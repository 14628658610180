import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Input, notification, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from "../../components/Card";
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import { FormRequest } from "../../api/controllers/contact-controller";

const FlightReservation = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("flight-reservation")

    const [lang, setLang] = useState()
    const [showNav, setShowNav] = useState();

    const saveLang = () => {
        if (localStorage.getItem('currentLanguageCode') === null) {
            setLang("tr")
        }
        else {
            setLang(localStorage.getItem('currentLanguageCode'))
        }

    }

    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };


    useEffect(() => {
        saveLang()
        window.scrollTo(0, 0)

    }, [])

    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <Row gutter={[20, 20]} >
                            <Col span={24}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto" }}>
                                    <div className='search-top'>
                                        <Text className='kesfet-baslik'>{t("uçakrezervasyonu")}</Text>
                                    </div>

                                    <div className="rez-detail-middle">
                                        <iframe id="awebframe" className='flight-rezervation' scrolling="auto" src={`https://summertour.onlineota.com/a/widget/search?Lang=${lang}`} allowtransparency="true"></iframe>
                                    </div>


                                </div>
                            </Col>

                        </Row>
                    </div>
                    <Info />
                </div>
            </div>
        </StickyContainer>
    )
}

export default FlightReservation

