import Router from './components/Router';
import './global.css'
import "antd/dist/antd.min.css";

import ApiProvider from './api/ApiProvider';


function App() {
  return (
    <ApiProvider>
      <Router />
    </ApiProvider>
  );
}

export default App;
