import { Button, Col, notification, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import CustomButton from './Buttons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Rezervation = ({ whiteText, priceData, handleCreate }) => {
    const { t, i18n } = useTranslation("common");
    let navigate = useNavigate();
    var url = window.location;
    let id = new URLSearchParams(url.search).get('id');
    let bp = new URLSearchParams(url.search).get('bp');
    let cp = new URLSearchParams(url.search).get('cp');
    let ap = new URLSearchParams(url.search).get('ap');
    let bc = new URLSearchParams(url.search).get('bc');
    let cc = new URLSearchParams(url.search).get('cc');
    let ac = new URLSearchParams(url.search).get('ac');
    let sign = localStorage.getItem('currencySign')

    const [isDisable, setIsDisable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };

    const [babyCount, setBabyCount] = useState(0)
    const [childCount, setChildCount] = useState(0)
    const [adultCount, setAdultCount] = useState(0)

    return (
        <div className='rezervation-root' >
            <Row gutter={[20, 20]} >
                {/* <Col span={24} className='rezervation-top'>
                    <div style={{ display: "flex", alignItems: "center", paddingBottom: "15px" }} >
                        <img style={{ paddingRight: "10px" }} src='/assets/img/takvim.svg' />
                        <Text className='rezervation-top-text1'>{t("zamanbilgileri")}</Text>
                    </div>
                    <div className='rezervation-top-text2'>
                        * 10/03/2022 - 10:30
                    </div>
                    <div className='rezervation-top-text2'>
                        * 13/03/2022 - 14:00
                    </div>
                </Col> */}
                {whiteText === "rezervasyonyaptır" ?
                    <Col span={24} className='rezervation-top'>
                        <div style={{ display: "flex", alignItems: "center", paddingBottom: "15px" }} >
                            <img style={{ paddingRight: "10px" }} src='/assets/img/takvim.svg' />
                            <Text className='rezervation-top-text1'>{t("fiyatbilgileri")}</Text>
                        </div>
                        <div className='rezervation-top-text2'>
                            * {t("bebek")} {priceData.priceInfant} {sign}
                        </div>
                        <div className='rezervation-top-text2'>
                            * {t("çocuk")} {priceData.priceChild} {sign}
                        </div>
                        <div className='rezervation-top-text2'>
                            * {t("yetişkin")} {priceData.priceAdult} {sign}
                        </div>
                    </Col>
                    : whiteText === "sepeteekle" ?
                        <Col span={24} className='rezervation-top'>
                            <div style={{ display: "flex", alignItems: "center", paddingBottom: "15px" }} >
                                <img style={{ paddingRight: "10px" }} src='/assets/img/takvim.svg' />
                                <Text className='rezervation-top-text1'>{t("ödenecektutar")}</Text>
                            </div>
                            {bc > 0 ?
                                <div className='rezervation-top-bottom'>
                                    <div className='rezervation-top-text2'>
                                        * {t("bebek")} {bp} {sign}
                                    </div>
                                    <div className='rezervation-top-text2'>
                                        x{bc}
                                    </div>
                                </div>
                                : <></>}
                            {cc > 0 ?
                                <div className='rezervation-top-bottom'>
                                    <div className='rezervation-top-text2'>
                                        * {t("çocuk")} {cp} {sign}
                                    </div>
                                    <div className='rezervation-top-text2'>
                                        x{cc}
                                    </div>
                                </div> :
                                <></>}
                            {ac > 0 ?
                                <div className='rezervation-top-bottom'>
                                    <div className='rezervation-top-text2'>
                                        * {t("yetişkin")} {ap} {sign}
                                    </div>
                                    <div className='rezervation-top-text2'>
                                        x{ac}
                                    </div>
                                </div>
                                :
                                <></>}
                            <div className='rezervation-top-bottom-bottom'>
                                {/* <div className='rezervation-top-bottom'>
                                    <div className='rezervation-top-text2'>
                                        {t("aratoplam")}
                                    </div>
                                    <div className='rezervation-top-text2'>
                                        {bc * bp + cc * cp + ac * ap} €
                                    </div>
                                </div>
                                <div className='rezervation-top-bottom'>
                                    <div className='rezervation-top-text2'>
                                        {t("vergi")}
                                    </div>
                                    <div className='rezervation-top-text2'>
                                        28 €
                                    </div>
                                </div> */}
                                <div className='rezervation-top-bottom'>
                                    <div className='rezervation-top-text2'>
                                        {t("toplam")}
                                    </div>
                                    <div className='rezervation-top-text2'>
                                        {(bc * bp + cc * cp + ac * ap).toFixed(2)} {sign}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        : <></>
                }
                {isDisable === false && whiteText === "rezervasyonyaptır" ? <Col span={24} className='rezervation-bottom'>
                    <Row gutter={[0, 20]} className='rezervation-bottom-items'>
                        <Col span={24} className='rezervation-bottom-item1' >
                            <Text onClick={() => setIsDisable(false)} >
                                {t(whiteText)}
                            </Text>
                        </Col>
                        <Row gutter={[0, 20]} style={{ width: "100%" }}>
                            <Row className='rezervation-bottom-item2'>
                                <Col span={8} className='rezervation-bottom-item2-1'>
                                    <div>
                                        {t("bebek2")}
                                    </div>
                                    <div style={{ borderBottom: "1px solid rgba(44, 62, 80, 0.5) ", paddingBottom: "5px" }}>
                                        <img onClick={() => babyCount > 0 ? setBabyCount(babyCount - 1) : <></>} src='/assets/img/eksi.svg' style={{ marginRight: "5px", cursor: "pointer" }} />
                                        <Text className='rezervation-sayac-text'>{babyCount}</Text>
                                        <img onClick={() => babyCount > -1 ? setBabyCount(babyCount + 1) : <></>} src='/assets/img/arti.svg' style={{ marginLeft: "5px", cursor: "pointer" }} />
                                    </div>
                                </Col>
                                <Col span={8} className='rezervation-bottom-item2-1'>
                                    <div>
                                        {t("çocuk2")}
                                    </div>
                                    <div style={{ borderBottom: "1px solid rgba(44, 62, 80, 0.5) ", paddingBottom: "5px" }}>
                                        <img onClick={() => childCount > 0 ? setChildCount(childCount - 1) : <></>} src='/assets/img/eksi.svg' style={{ marginRight: "5px", cursor: "pointer" }} />
                                        <Text className='rezervation-sayac-text'>{childCount}</Text>
                                        <img onClick={() => childCount > -1 ? setChildCount(childCount + 1) : <></>} src='/assets/img/arti.svg' style={{ marginLeft: "5px", cursor: "pointer" }} />
                                    </div>
                                </Col>
                                <Col span={8} className='rezervation-bottom-item2-1'>
                                    <div>
                                        {t("yetişkin2")}
                                    </div>
                                    <div style={{ borderBottom: "1px solid rgba(44, 62, 80, 0.5) ", paddingBottom: "5px" }}>
                                        <img onClick={() => adultCount > 0 ? setAdultCount(adultCount - 1) : <></>} src='/assets/img/eksi.svg' style={{ marginRight: "5px", cursor: "pointer" }} />
                                        <Text className='rezervation-sayac-text'>{adultCount}</Text>
                                        <img onClick={() => adultCount > -1 ? setAdultCount(adultCount + 1) : <></>} src='/assets/img/arti.svg' style={{ marginLeft: "5px", cursor: "pointer" }} />
                                    </div>
                                </Col>
                            </Row>
                            <Col span={24} className='rezervation-bottom-item3'>
                                <Row className='rezervation-bottom-item3-top'>
                                    <Col span={7}>
                                        <div className='rezervation-button-item3-text'>{t("adet")}</div>
                                    </Col>
                                    <Col span={10}>
                                        <div className='rezervation-button-item3-text'>{t("tip")}</div>
                                    </Col>
                                    <Col span={7}>
                                        <div className='rezervation-button-item3-text'>{t("tutar")}</div>
                                    </Col>
                                </Row>
                                <Col span={24} >
                                    {babyCount > 0 ? <Row className='rezervation-bottom-item3-top'>
                                        <Col span={7}>
                                            <div className='rezervation-button-item3-text2'>{babyCount}</div>
                                        </Col>
                                        <Col span={10}>
                                            <div className='rezervation-button-item3-text2'>{t("bebek2")}</div>
                                        </Col>
                                        <Col span={7}>
                                            <div className='rezervation-button-item3-text2'>{priceData.priceInfant * babyCount} {sign}</div>
                                        </Col>
                                    </Row> : <></>}
                                    {childCount > 0 ? <Row className='rezervation-bottom-item3-top'>
                                        <Col span={7}>
                                            <div className='rezervation-button-item3-text2'>{childCount}</div>
                                        </Col>
                                        <Col span={10}>
                                            <div className='rezervation-button-item3-text2'>{t("çocuk2")}</div>
                                        </Col>
                                        <Col span={7}>
                                            <div className='rezervation-button-item3-text2'>{priceData.priceChild * childCount} {sign}</div>
                                        </Col>
                                    </Row> : <></>}
                                    {adultCount > 0 ? <Row className='rezervation-bottom-item3-top'>
                                        <Col span={7}>
                                            <div className='rezervation-button-item3-text2'>{adultCount}</div>
                                        </Col>
                                        <Col span={10}>
                                            <div className='rezervation-button-item3-text2'>{t("yetişkin2")}</div>
                                        </Col>
                                        <Col span={7}>
                                            <div className='rezervation-button-item3-text2'>{priceData.priceAdult * adultCount} {sign}</div>
                                        </Col>
                                    </Row> : <></>}
                                </Col>
                                <div className='rezervation-bottom-item3-bottom'>
                                    <Text className='rezervation-button-item3-text'>{t("toplam")}</Text>
                                    <Text className='rezervation-button-item3-text' style={{ paddingLeft: "10px" }}>{(priceData.priceInfant * babyCount + priceData.priceChild * childCount + priceData.priceAdult * adultCount).toFixed(2)}  {sign}</Text>
                                </div>
                            </Col>
                            <Col span={24}>
                                <CustomButton onClick={() =>
                                    adultCount > 0 ? setIsDisabled(true) &
                                        setTimeout(() => {
                                            setIsDisabled(false)
                                        }, 1500) &
                                        navigate(`../rez-detail?id=${id}&bc=${babyCount}&cc=${childCount}&ac=${adultCount}&bp=${priceData.priceInfant}&cp=${priceData.priceChild}&ap=${priceData.priceAdult}`)
                                        : setIsDisabled(true) &
                                        setTimeout(() => {
                                            setIsDisabled(false)
                                        }, 1500) & openNotification({ message: "en az 1 adet yetişkin ekleyiniz", type: "error" })} loading={isDisabled} disabled={isDisabled} text={t("devamet")} colorType={"login-button-blue"} />
                            </Col>
                            <Col span={24}>
                                <CustomButton onClick={() => setIsDisable(true)} text={t("iptalet")} colorType={"login-button-white"} />
                            </Col>
                        </Row>
                    </Row>
                </Col> :
                    <Row onClick={whiteText === "rezervasyonyaptır" ? () => setIsDisable(false) : whiteText === "sepeteekle" ? () => handleCreate() : <></>} gutter={[0, 20]} className='rezervation-bottom-isdisable'>
                        <Col span={24} className='rezervation-bottom-item1' >
                            <Text >
                                {t(whiteText)}
                            </Text>
                        </Col>
                    </Row>
                }
            </Row>
        </div>
    )
}
export default Rezervation;