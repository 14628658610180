import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Input, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from '../../components/Card';
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import { getActivityPagableRequest, getActivityRequest } from "../../api/controllers/activity-controller";

const DiscoverMore = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("discorver-more")

    let navigate = useNavigate();

    const [showNav, setShowNav] = useState();
    const [showCard, setShowCard] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isMore, setIsMore] = useState(false)
    const [activityData, setActivityData] = useState()
    const [pagableData, setPagableData] = useState({
        page: 0,
        size: 10,
        sort: [
            ""
        ]
    })

    const loadMore = async () => {

        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 1000);

        try {
            let res = await getActivityPagableRequest(pagableData)
            if (res.data.content.length < 1) {

                setIsMore(true)
            } else {
                if (res) {
                    let result = activityData
                    res.data.content.map((val) =>
                        result.push(val)
                    )
                    setActivityData(result)
                    pagableData.page = pagableData.page + 1
                    pagableData.size = pagableData.size
                    pagableData.sort = [""]
                    setPagableData({ ...pagableData })
                }

            }

        } catch (error) {

        }
    }


    const fetchActivity = async () => {

        try {
            let res = await getActivityPagableRequest(pagableData)
            if (res) {
                setActivityData(res.data.content)
                pagableData.page = pagableData.page + 1
                pagableData.size = pagableData.size
                pagableData.sort = [""]
                setPagableData({ ...pagableData })
                setShowCard(true)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchActivity()
        window.scrollTo(0, 0)

    }, [])


    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <Row gutter={[20, 20]} >
                            <Col span={24}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto" }}>
                                    <div className='search-top'>
                                        <Text className='kesfet-baslik'>{t("dahafazlasınıkeşfedin")}</Text>
                                    </div>

                                    {showCard === true ? <Card discoverData={activityData} loadMore={loadMore} /> : <></>}

                                </div>
                            </Col>

                        </Row>
                        {isMore === false ?
                            <div className='kesfet-bottom'>
                                <Button loading={isLoading} disabled={isLoading} onClick={loadMore} className='kesfet-button'>
                                    {t("dahafazlasınıkeşfedin")}
                                </Button>
                            </div>
                            : <></>
                        }
                    </div>
                    <Info />
                </div>
            </div>
        </StickyContainer>
    )
}

export default DiscoverMore

