// Import Swiper styles
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import Text from 'antd/lib/typography/Text';
import CustomButton from './Buttons';
import { Button, notification } from "antd";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';


const Slider = ({ activityData }) => {
    const { t, i18n } = useTranslation("common");
    let navigate = useNavigate();
    SwiperCore.use([Autoplay]);
    SwiperCore.use([Pagination]);
    SwiperCore.use([Navigation]);


    return (
        <div className="slider-root" >

            <Swiper
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                spaceBetween={0}
                speed={2000}
                slidesPerView={1}
                /*  onSwiper={(swiper) => console.log(swiper)}
                 onSlideChange={() => console.log('slide change')} */
                loop={true}
            >
                <img className="swiper-button-prev" src="/assets/img/slider-arrow-left.svg" />
                <img className="swiper-button-next" src="/assets/img/slider-arrow-right.svg" />

                {activityData.map((val, i) => (
                    <SwiperSlide key={i} className='sliderPhoto' style={{ backgroundImage: `url(${val.url})` }}>
                        <div className='sliderInside'>
                            <div className='sliderMiddle' >
                                <div className='sliderMiddleItems' >
                                    <div className='sliderMiddleItem1'>
                                        {val.title}
                                    </div>
                                    <div className='sliderMiddleItem2'>
                                        {val.city}
                                    </div>
                                    <div className='sliderMiddleItem3'>
                                        <CustomButton onClick={() => navigate(`../detail?id=${val.id}`)} text={t("bilgialın")} colorType={"login-button-transparent-white"} />
                                    </div>

                                </div>
                                {/*  <div className='sliderDownItems' >
                                    <img src="/assets/img/yukari-kaydir.svg" />
                                    <div className='sliderDownItem2'>
                                        {t("keşfetmekiçin")}
                                    </div>
                                    <div className='sliderDownItem2'>
                                        {t("kaydırın")}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </SwiperSlide>
                ))
                }

            </Swiper>
        </div>

    );
};

export default Slider