// Import Swiper styles
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import Text from 'antd/lib/typography/Text';
import CustomButton from './Buttons';
import { Button, Col, notification, Row, Tooltip } from "antd";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';


const LastView = ({ discoverData }) => {
    const { t, i18n } = useTranslation("common");
    let sign = localStorage.getItem('currencySign')
    let lastVisitedData = JSON.parse(localStorage.getItem("lastVisited") || "[]");
    let navigate = useNavigate();
    SwiperCore.use([Autoplay]);
    SwiperCore.use([Pagination]);
    SwiperCore.use([Navigation]);

    /*  const swiper = new Swiper('.swiper', {
         // Default parameters
         slidesPerView: 1,
         spaceBetween: 10,
         // Responsive breakpoints
         breakpoints: {
             // when window width is >= 320px
             320: {
                 slidesPerView: 2,
                 spaceBetween: 20
             },
             // when window width is >= 480px
             480: {
                 slidesPerView: 3,
                 spaceBetween: 30
             },
             // when window width is >= 640px
             640: {
                 slidesPerView: 4,
                 spaceBetween: 40
             }
         }
     }) */

    return (
        lastVisitedData.length > 1 ? <div className='last-view'>
            <div className="last-view-top">
                <Text className="kesfet-baslik">{t("sonziyaretedilenler")}</Text>
            </div>
            <div className={lastVisitedData.length == 2 ? "last-view-root-az-2" : lastVisitedData.length == 3 ? "last-view-root-az-3" : "last-view-root"} >

                <Swiper
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    autoplay={false}
                    spaceBetween={5}
                    speed={500}
                    slidesPerView={2}
                    breakpoints={{
                        1200: {
                            spaceBetween: 25,
                            slidesPerView: lastVisitedData.length,
                        },
                        768: {
                            spaceBetween: 15,
                            slidesPerView: 3,
                        },
                    }}
                    /*  onSwiper={(swiper) => console.log(swiper)}
                     onSlideChange={() => console.log('slide change')} */
                    loop={true}
                >
                    <img className="swiper-button-prev" src="/assets/img/slider-arrow-left.svg" />
                    <img className="swiper-button-next" src="/assets/img/slider-arrow-right.svg" />

                    {discoverData.map((val, i) => (
                        <SwiperSlide key={i} className='sliderPhoto' >
                            <div className='last-view-root-2' onClick={() => navigate(`../detail?id=${val.id}`)} style={{ backgroundImage: `url(${val.url})` }} >
                                <div className='last-view-overlay'>
                                    <div className='card-small-top'>
                                        <img style={{ paddingRight: "10px" }} src='/assets/img/location.svg' />
                                        <Text className='card-top-item1'>
                                            {val.city}
                                        </Text>
                                    </div>
                                    <div className='card-bottom'>
                                        <div >
                                            <Text className='card-small-text'>
                                                {val.title}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                    }

                </Swiper>
            </div>
        </div > : <></>

    );
};

export default LastView