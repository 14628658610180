import axios from "axios";

export const paymentRequest = (cartData) => axios.post(`/payment/createPayment`, cartData);

export const paymentNonMemberRequest = (cartData) => axios.post(`/payment/withoutCustomerCreatePayment`, cartData);

export const confirmTokenRequest = (token) => axios.get(`/payment/iyzipay/token?token=${token}`);

export const postCartRequest = (cart) => axios.post(`/payment/cartTranslator`, cart);

export const rezervationRequest = (cartData) => axios.post(`/payment/customerReservation`, cartData);

export const rezervationNonMemberRequest = (cartData) => axios.post(`/payment/guestReservation`, cartData);
