import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import Slider from '../../components/Slider';
import { Button, Col, Input, notification, Row, Select } from 'antd';
import Card from '../../components/Card';
import Info from '../../components/Info';
import LastView from '../../components/LastView';
import CustomButton from '../../components/Buttons';
import { useNavigate } from "react-router-dom";
import NavBar from '../../components/NavBar';
import StickyTop from '../../components/StickyTop';
import { useTranslation } from 'react-i18next';
import { getCategoryRequest } from '../../api/controllers/category-controller';
import { getActivityDiscoverRequest, getActivitySliderRequest } from '../../api/controllers/activity-controller';


const HomePage = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("home-page")
    let lastVisitedData = JSON.parse(localStorage.getItem("lastVisited") || "[]");

    let navigate = useNavigate();
    const { Option } = Select;

    const [state, setState] = useState()
    const [showNav, setShowNav] = useState();
    const [searchData, setSearchData] = useState({
        categoryId: 0,
        city: ""
    })
    const [categoryData, setCategoryData] = useState([])
    const [sliderData, setSliderData] = useState([])
    const [discoverData, setDiscoverData] = useState([])

    function handleChange(value) {
        searchData.categoryId = value.key
        setSearchData({ ...searchData })

    }

    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };

    const fetchCategory = async () => {

        try {
            let res = await getCategoryRequest()
            if (res) {
                setCategoryData(res.data)
            }
        } catch (error) {
        }
    }

    const fetchActivityDiscover = async () => {

        try {
            let res = await getActivityDiscoverRequest()
            if (res) {
                setDiscoverData(res.data)
            }
        } catch (error) {
            error?.response?.data.map((val, i) =>
                openNotification({ message: val.message, type: "error" })
            )
        }
    }

    const fetchActivitySlider = async () => {

        try {
            let res = await getActivitySliderRequest()
            if (res) {
                setSliderData(res.data)
            }
        } catch (error) {
            error?.response?.data.map((val, i) =>
                openNotification({ message: val.message, type: "error" })
            )
        }
    }

    const search = () => {
        if (searchData.city === "" && searchData.categoryId === 0) {
            openNotification({ message: t("aramayapmakiçinşehirvekategoriaynıandaboşolamaz"), type: "warning" })
        } else {
            navigate(`../search?id=${searchData.categoryId}&city=${searchData.city}`)
        }
    }

    useEffect(() => {
        fetchCategory()
        fetchActivityDiscover()
        fetchActivitySlider()
        window.scrollTo(0, 0)

    }, [])



    return (
        <StickyContainer>

            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />

            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <Slider activityData={sliderData} />

            <div className='arama-root' style={{ backgroundImage: "url(/assets/img/world.svg)" }} >
                <div className='arama-top'>
                    <div >
                        <Text className='arama-top-item1'>
                            {t("arama")} {''}
                        </Text>
                        <Text className='arama-top-item2'>
                            {t("yapın2")}
                        </Text>
                    </div>
                    <Text className='arama-top-item3'>
                        {t("aşağıdayeralanları")}
                    </Text>
                </div>
                <Row gutter={20} className='arama-middle'>
                    <Col xs={24} sm={24} md={8} className='arama-middle-items'>
                        <Text className='arama-middle-item1'>
                            {t("kategori")}
                        </Text>
                        <Select
                            className='arama-middle-item2'
                            labelInValue
                            placeholder={t("kategoriseçiniz")}
                            style={{ width: "100%" }}
                            onSelect={handleChange}
                            dropdownStyle={{ zIndex: "1000" }}
                        >
                            {
                                categoryData.map((val, i) => (
                                    <Option key={val.id} value={i + 1} >{val.title}</Option>
                                )
                                )
                            }
                        </Select>
                    </Col>
                    <Col xs={24} sm={24} md={8} className='arama-middle-items'>
                        <Text className='arama-middle-item1'>
                            {t("şehir")}
                        </Text>
                        <Input onChange={(e) => {
                            searchData.city = e.target.value
                            setSearchData({ ...searchData })
                        }} className='arama-middle-item2' placeholder={t("şehirgiriniz")} />
                    </Col>
                    <Col xs={24} sm={24} md={8} className='arama-middle-items-last'>
                        <div className='arama-middle-item3'>
                            <CustomButton onClick={search} text={t("aramayabaşla")} colorType={"login-button-blue"} />
                        </div>
                    </Col>
                </Row>
                {/* <div className='arama-bottom'>
                    <Row gutter={[20, 20]} className='arama-bottom-top'>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className='arama-bottom-divider1'></Col>
                        <Col xs={12} sm={10} md={7} lg={6} xl={5} xxl={4}>
                            <Text className='arama-bottom-text1'>
                                {t("önerilenaramalar")}
                            </Text>
                        </Col>
                        <Col xs={8} sm={11} md={14} lg={15} xl={16} xxl={17} className='arama-bottom-divider2'></Col>
                    </Row>
                    <Row gutter={[20, 20]} className='arama-bottom-bottom'>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <div className='arama-bottom-bottom-item1' style={{ backgroundImage: "url(/assets/img/bot.jpg)" }}>
                                Rize - Rafting
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <div className='arama-bottom-bottom-item1' style={{ backgroundImage: "url(/assets/img/bot.jpg)" }}>
                                Marmaris - Yat Turu
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <div className='arama-bottom-bottom-item1' style={{ backgroundImage: "url(/assets/img/bot.jpg)" }}>
                                Kapadokya - Balon Turu
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6}>
                            <div className='arama-bottom-bottom-item1' style={{ backgroundImage: "url(/assets/img/bot.jpg)" }}>
                                Kemer - Kanyon Turu
                            </div>
                        </Col>
                    </Row>
                </div> */}
            </div>

            <LastView discoverData={lastVisitedData} />

            <div className='kesfet-root'>
                <div className='kesfet-top'>
                    <Text className='kesfet-baslik'>{t("keşfedin")}</Text>
                    <Text className='kesfet-text'>{t("sizeönerdiğimizetkinlikleri")}</Text>
                </div>

                <Card discoverData={discoverData} />

                <div className='kesfet-bottom'>
                    <Button onClick={() => navigate("../discover-more")} className='kesfet-button'>
                        {t("dahafazlasınıkeşfedin")}
                    </Button>
                </div>
            </div>

            <Info />



        </StickyContainer >
    );
};

export default HomePage;

