import { Input, InputNumber, Layout, Menu, Tabs, Select, Modal, Collapse, Row, Col, Button, notification } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import './AdminPanel.css';
import { useNavigate } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/Buttons';
import Citys from '../../components/Citys';
import { addActivityRequest } from '../../api/admincontrollers/activity-controller';
import { getCategoryRequest } from '../../api/admincontrollers/category-controller';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const Activity = () => {
  const { t, i18n } = useTranslation("common");
  const { Option } = Select;
  const { Panel } = Collapse;
  let navigate = useNavigate();

  const [IsDisabled, setIsDisabled] = useState(false)
  const [data, setData] = useState([])

  const [activityData, setActivityData] = useState({
    city: "",
    priceEuroAdult: 10,
    priceEuroChild: 10,
    priceEuroInfant: 0,
    categoryId: 0,
    title: {
      tr: "",
      en: "",
      de: "",
      ru: "",
      ar: "",
      pl: "",
      fr: "",
      sq: "",
      es: "",
      localizedText: ""
    },
    overview: {
      tr: "",
      en: "",
      de: "",
      ru: "",
      ar: "",
      pl: "",
      fr: "",
      sq: "",
      es: "",
      localizedText: ""
    }
  })
  const openNotification = ({ message, type }) => {
    if (type === "info") {
      notification.info({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "error") {
      notification.error({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "success") {
      notification.success({
        message: message,
        placement: 'bottomLeft',
      });
    }
  };

  const saveActivity = async () => {
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    }, 1500);

    try {
      let res = await addActivityRequest(activityData)
      if (res) {
        navigate("/admin/activityView")
        openNotification({ message: "Aktivite Eklendi", type: "success" })
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const fetchCategory = async () => {
    try {
      let res = await getCategoryRequest()
      if (res) {
        setData(res.data)
      }

      /*  if (res?.status === 200 && res?.data?.role === "ROLE_ADMIN") {
           setTimeout(() => {
               navigate("/admin/home-page")
           }, 500);
       } else if (res?.status === 200 && res?.data?.role === "ROLE_USER") {
           setTimeout(() => {
               navigate("/")
           }, 500);
       } */
    } catch (error) {
    }
  }

  useEffect(() => {

     fetchCategory()


  }, [])


  return (

    <div className='kategori-root' >
      <div className='kategori-root-top'>
        <Text className='kategori-baslik'>Aktivite Ekleme</Text>
        <div style={{ width: "100px" }}>
          <CustomButton loading={IsDisabled} onClick={saveActivity} text={"Kaydet"} colorType={"login-button-blue"} />
        </div>
      </div>

      <div className='kategori-content-root-mutual'>
        <Row gutter={[20, 0]} className='kategori-content-root-mutual-inside'>
          <Col className='kategori-left' xs={24} sm={24} md={12} lg={12}>
            <Text className='kategori-dil'>Bebek Fiyatı</Text>
            <InputNumber onChange={(e) => {
              activityData.priceEuroInfant = e
              setActivityData({ ...activityData })
            }} value={activityData?.priceEuroInfant} style={{ paddingBottom: "10px" }} addonAfter="€" defaultValue={0} />
            <Text className='kategori-dil'>Çocuk Fiyatı</Text>
            <InputNumber onChange={(e) => {
              activityData.priceEuroChild = e
              setActivityData({ ...activityData })
            }} value={activityData?.priceEuroChild} style={{ paddingBottom: "10px" }} addonAfter="€" defaultValue={0} />
            <Text className='kategori-dil'>Yetişkin Fiyatı</Text>
            <InputNumber onChange={(e) => {
              activityData.priceEuroAdult = e
              setActivityData({ ...activityData })
            }} value={activityData?.priceEuroAdult} style={{ paddingBottom: "10px" }} addonAfter="€" defaultValue={0} />
          </Col>
          <Col className='kategori-right' xs={24} sm={24} md={12} lg={12}>
            <Text className='kategori-dil'>Akitivitenin açılacağı ili yazınız</Text>
            <Input onChange={(e) => {
              activityData.city = e.target.value.toUpperCase()
              setActivityData({ ...activityData })
            }} value={activityData?.city} className="admin-input-city" size="large" placeholder="Aktivite şehri" />
            <Text className='kategori-dil'>Aktivitenin içinde olacağı kategoriyi seçin</Text>
            <Select
              showSearch
              getPopupContainer={triggerNode => triggerNode.parentElement}
              style={{ width: '100%', border: "1px solid lightgrey", marginBottom: "10px" }}
              placeholder="Kategoriler"
              optionFilterProp="children"
              onSelect={(e) => {
                activityData.categoryId = e
                setActivityData({ ...activityData })
              }}
            >
              {
                data.map((val, i) => (
                  <Option key={i} value={val.id}>{val.title.en}</Option>
                )
                )
              }
            </Select>
          </Col>
          {/* <Text className='kategori-dil'>Fotoğraf</Text>
        <div style={{ maxWidth: "250px" }}>
          <CustomButton text={"Arka Plan Fotoğrafı Yükle"} colorType={"login-button-blue"} />
        </div> */}
        </Row>
      </div>
      <div className='kategori-root-bottom'>
        <Collapse defaultActiveKey={['1']} style={{ marginBottom: "10px", marginTop: "10px", width: "100%" }} >
          <Panel header="TÜRKÇE" key="1">
            <Input onChange={(e) => {
              activityData.title.tr = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.tr} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
/*                   console.log('Editor is ready to use!', editor);
 */                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.tr = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
          <Panel header="İNGİLİZCE" key="2">
            <Input onChange={(e) => {
              activityData.title.en = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.en} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.en = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
          <Panel header="ALMANCA" key="3">
            <Input onChange={(e) => {
              activityData.title.de = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.de} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.de = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
          <Panel header="RUSÇA" key="4">
            <Input onChange={(e) => {
              activityData.title.ru = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.ru} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.ru = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
          <Panel header="ARAPÇA" key="5">
            <Input onChange={(e) => {
              activityData.title.ar = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.ar} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.ar = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
          <Panel header="LEHÇE" key="6">
            <Input onChange={(e) => {
              activityData.title.pl = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.pl} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.pl = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
          <Panel header="FRANSIZCA" key="7">
            <Input onChange={(e) => {
              activityData.title.fr = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.fr} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.fr = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
          <Panel header="ARNAVUTÇA" key="8">
            <Input onChange={(e) => {
              activityData.title.sq = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.sq} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.sq = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
          <Panel header="İSPANYOLCA" key="9">
            <Input onChange={(e) => {
              activityData.title.es = e.target.value
              setActivityData({ ...activityData })
            }} value={activityData?.title?.es} className="admin-input" size="large" placeholder="Aktivite başlığı" />
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data="<p></p>"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  activityData.overview.es = data
                  setActivityData({ ...activityData })
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
              />
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  )
}


export default Activity;

