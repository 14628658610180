import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Button, Input, message, Modal, notification } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import StickyTop from "../../components/StickyTop";
import { useTranslation } from 'react-i18next';
import { getMeRequest, LoginRequest, ResetPasswordRequest, ResetPasswordRequest2 } from "../../api/controllers/account-controller";


const Login = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("login")

    let navigate = useNavigate();

    const [isDisabled, setIsDisabled] = useState(false);

    const [showNav, setShowNav] = useState();
    const [rememberme, setRememberme] = useState(false)
    const [passwordResetModal, setPasswordResetModal] = useState()
    const [passwordResetModal2, setPasswordResetModal2] = useState()
    const [newPassword, setNewPassword] = useState("")
    const [newPassword2, setNewPassword2] = useState("")
    const [resetCode, setResetCode] = useState("")
    const [resetMail, setResetMail] = useState("")

    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
        rememberMe: rememberme
    })

    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };

    const checkAuthentication = async () => {
        try {
            let res = await getMeRequest()
            if (res?.status === 200 && res?.data?.role === "ROLE_ADMIN") {
                setTimeout(() => {
                    navigate("/admin/home-page")
                }, 500);
            } else if (res?.status === 200 && res?.data?.role === "ROLE_USER") {
                setTimeout(() => {
                    navigate("/")
                }, 500);
            }
        } catch (error) {
        }
    }



    const ResetPassword = async () => {
        if (resetMail) {
            try {
                let res = await ResetPasswordRequest({ email: resetMail })
                if (res) {
                    openNotification({ message: t("mailinizikontroledin"), type: "success" })
                    setPasswordResetModal2(true)
                }
            } catch (error) {
                error?.response?.data.map((val, i) =>
                    openNotification({ message: val.message, type: "error" })
                )
            }
        }
        else {
            openNotification({ message: t("değerlerboşbırakılamaz"), type: "error" })
        }
    }

    const ResetPassword2 = async () => {
        if (resetCode && newPassword && newPassword2 && resetMail) {
            if (newPassword === newPassword2) {
                try {
                    let res = await ResetPasswordRequest2({ verificationCode: resetCode, newPassword: newPassword, newEmail: resetMail })
                    if (res) {
                        openNotification({ message: t("şifrenizdeğiştirildi"), type: "success" })
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                } catch (error) {
                    error?.response?.data.map((val, i) =>
                        openNotification({ message: val.message, type: "error" })
                    )
                }
            }
            else {
                openNotification({ message: t("şifreleruyuşmuyor"), type: "error" })
            }
        }
        else {
            openNotification({ message: t('değerlerboşbırakılamaz'), type: "error" })
        }
    }

    const login = async (e) => {
        e.preventDefault()
        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false)
        }, 2500);
        if (loginData.email && loginData.password) {
            try {
                let res = await LoginRequest(loginData)
                if (res) {
                    openNotification({ message: t('girişbaşarılı'), type: "success" })
                    checkAuthentication()
                }
            } catch (error) {
                error?.response?.data.map((val, i) =>
                    openNotification({ message: val.message, type: "error" })
                )
            }
        }
        else {
            openNotification({ message: 'değerlerboşbırakılamaz', type: "error" })
        }
    }

    useEffect(() => {

        checkAuthentication()


    }, [])






    return (
        <div className="login-root" style={{ backgroundImage: "url(/assets/img/tracking.jpg)" }}>

            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-blue"} setShowNav={setShowNav} />

            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <form onSubmit={login} className="login-root-right">
                <div className="login-root-right2">
                    <div className="login-right-top">
                        <Text className="login-right-top1">{t("giriş")}</Text>
                        <Text className="login-right-top2"> {t("yapın")}</Text>
                    </div>
                    <div>
                        <Input onChange={(e) => {
                            loginData.email = e.target.value
                            setLoginData({ ...loginData })
                        }} value={loginData?.email} className="login-right-middle1" size="large" placeholder={t("mailadresiniz")} prefix={<img src='/assets/img/mail.svg' />} />
                        <div style={{ width: "100%", border: "1px solid rgba(44, 62, 80, 0.5)" }}></div>
                    </div>
                    <div>
                        <Input.Password onChange={(e) => {
                            loginData.password = e.target.value
                            setLoginData({ ...loginData })
                        }} value={loginData?.password} className="login-right-middle1" size="large" placeholder={t("şifreniz")} prefix={<img src='/assets/img/password.svg' />} />
                        <div style={{ width: "100%", border: "1px solid rgba(44, 62, 80, 0.5)", marginBottom: "20px" }}></div>
                    </div>
                    <div className="login-buttons">
                        <CustomButton loading={isDisabled} disabled={isDisabled} type="submit" text={t("girişyap")} colorType={"login-button-blue"} />
                        <div className="login-default-text" >{t("veya")}</div>
                        <CustomButton onClick={() => navigate('../register')} text={t("üyeol")} colorType={"login-button-transparent-blue"} />
                    </div>
                    <div onClick={() => setPasswordResetModal(true)} className="login-default-text2">
                        {t("şifremiunuttum-yenilemekistiyorum")}
                    </div>
                </div>
            </form>
            <Modal
                title={t("şifremisıfırlamakistiyorum")}
                visible={passwordResetModal}
                okText={t("tamam")}
                cancelText={t("iptal")}
                onOk={ResetPassword}
                onCancel={() => setPasswordResetModal(false)}
                width={150}
                className='verification-modal'
            >
                <Input onChange={(e) => {
                    setResetMail(e.target.value)
                }} className="rez-detail-middle-text2" size="large" placeholder={t("mailadresiniz")} prefix={<img src='/assets/img/mail.svg' />} />
            </Modal>
            <Modal
                title={t("şifremisıfırlamakistiyorum")}
                visible={passwordResetModal2}
                onOk={ResetPassword2}
                okText={t("tamam")}
                cancelText={t("iptal")}
                maskClosable={false}
                onCancel={() => setPasswordResetModal2(false)}
                width={300}
                className='reset-modal'
                bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
            >
                <Input onChange={(e) => {
                    setResetCode(e.target.value)
                }} className="login-right-middle3" size="large" placeholder={t("resetkod")} prefix={<img src='/assets/img/mail.svg' />} />
                <Input.Password onChange={(e) => {
                    setNewPassword(e.target.value)
                }} className="login-right-middle3" size="large" placeholder={t("şifreniz")} prefix={<img src='/assets/img/password.svg' />} />
                <Input.Password onChange={(e) => {
                    setNewPassword2(e.target.value)
                }} className="login-right-middle3" size="large" placeholder={t("şifreniztekrar")} prefix={<img src='/assets/img/password.svg' />} />
            </Modal>
        </div >
    )
}

export default Login