import { CloseOutlined, LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Input, Row, Tabs, Collapse, Tooltip, Switch, Alert } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from "../../components/Card";
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import { getMeRequest } from "../../api/controllers/account-controller";
import { fetchRezervationRequest } from "../../api/controllers/customer-controller";
import moment from "moment";

const MyAccount = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("my-account")

    const { TabPane } = Tabs;
    const { Panel } = Collapse;


    let sign = localStorage.getItem('currencySign')

    const [showNav, setShowNav] = useState();
    const [myAccountData, setMyAccountData] = useState([])
    const [orderData, setOrderData] = useState([])

    const getMyAccount = async () => {
        try {
            let res = await getMeRequest()
            setMyAccountData(res.data)

        } catch (error) {
        }
    }

    const getRezervation = async () => {
        try {
            let res = await fetchRezervationRequest()
            if (res) {
                setOrderData(res?.data)
            }

        } catch (error) {

        }
    }

    const CartCard = ({ val, z, i }) => {
        const [isDisabled, setIsDisabled] = useState(false)

        return (
            <div className="order-cart-root" onClick={() => setIsDisabled(!isDisabled)}>
                <div className="order-cart-right">
                    <div className="shopping-cart-top">
                        <Row gutter={[10, 0]}>
                            <Col>
                                <Text className="shopping-cart-text1">{z.localizedActivityDto.title}</Text>
                            </Col>
                            <Col>
                                <Text className="shopping-cart-text2">{z.localizedActivityDto.city}</Text>
                            </Col>
                        </Row>
                        {
                            val.cartStatus === "Payed" ?
                                <div className="shopping-cart-payed">{t("ödendi")}</div>
                                :
                                val.cartStatus === "Reserved" ?
                                    <div className="shopping-cart-reserved">{t("rezerve")}</div>
                                    :
                                    val.cartStatus === "WaitingForPayment" ?
                                        <div className="shopping-cart-waiting">{t("ödemebekleniyor")}</div>
                                        :
                                        val.cartStatus === "Failed" ?
                                            <div className="shopping-cart-failed">{t("birsorunoluştu")}</div>
                                            : <></>
                        }
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Row className="shopping-cart-bottom" >
                            <Col xs={24} sm={24} md={10} lg={10} xl={10} className='shopping-cart-bottom-outside' >
                                <div className='shopping-cart-bottom-inside'>
                                    <Text className="shopping-cart-text3">{t("ziyaretçiler")} :</Text>
                                    {/*  <div style={{ display: "flex", flexDirection: "row", }}>
                                        {z?.travelerList?.adultData?.length > 0 ?
                                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                        <Text className='cart-text1'>{z?.travelerList?.adultData?.length}  </Text>
                                        <img className="cart-icon" src="/assets/img/ADULTS.svg" />
                                        </div>
                                        : <></>}
                                        {z?.travelerList?.childData?.length > 0 ?
                                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                        <Text className='cart-text1'>{z?.travelerList?.childData?.length}  </Text>
                                        <img className="cart-icon" src="/assets/img/CHILDS.svg" />
                                        </div> : <></>}
                                        {z?.travelerList?.infantData?.length > 0 ?
                                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                        <Text className='cart-text1'>{z?.travelerList?.infantData?.length}  </Text>
                                        <img className="cart-icon2" src="/assets/img/BABY.svg" />
                                        </div> : <></>}
                                        </div> */}
                                    {z.travelerList.filter(function (item) {
                                        if (item.travelerType === "Adult") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }).length > 0 ?
                                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                            <Text className='cart-text1'>{z.travelerList.filter(function (item) {
                                                if (item.travelerType === "Adult") {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }).length}  </Text>
                                            <Tooltip title={t("yetişkin2")}>
                                                <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/ADULTS.svg" />} />
                                            </Tooltip>
                                        </div> : <></>
                                    }
                                    {z.travelerList.filter(function (item) {
                                        if (item.travelerType === "Child") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }).length > 0 ?
                                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                            <Text className='cart-text1'>{z.travelerList.filter(function (item) {
                                                if (item.travelerType === "Child") {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }).length}  </Text>
                                            <Tooltip title={t("çocuk2")}>
                                                <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/CHILDS.svg" />} />
                                            </Tooltip>
                                        </div> : <></>
                                    }
                                    {z.travelerList.filter(function (item) {
                                        if (item.travelerType === "Infant") {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }).length > 0 ?
                                        <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                            <Text className='cart-text1'>{z.travelerList.filter(function (item) {
                                                if (item.travelerType === "Infant") {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }).length}  </Text>
                                            <Tooltip title={t("bebek2")}>
                                                <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/BABY.svg" />} />
                                            </Tooltip>
                                        </div> : <></>
                                    }
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={7} lg={7} xl={7} className='shopping-cart-bottom-outside'>
                                <div className='shopping-cart-bottom-inside'>
                                    <Text className="shopping-cart-text3">{t("tarih")} :</Text>
                                    <Text style={{ paddingLeft: "10px" }}> {z.activityDate}</Text>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={7} lg={7} xl={7} className='shopping-cart-bottom-outside'>
                                <div className='shopping-cart-bottom-inside'>
                                    <Text className="shopping-cart-text3">{t("fiyat")} :</Text>
                                    <Text style={{ paddingLeft: "10px" }}>{(z.total).toFixed(2)} {sign}</Text>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {isDisabled === true ?
                        z.travelerList.map((zel, i) => (
                            <div key={i} className="order-detail">
                                <Row >
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <div style={{ paddingBottom: "10px" }}>
                                            <Text className="order-detail-baslik">{t("adınız")}</Text>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <Text className="order-detail-text">{zel.firstName}</Text>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <div style={{ paddingBottom: "10px" }}>
                                            <Text className="order-detail-baslik">{t("soyadınız")}</Text>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <Text className="order-detail-text">{zel.lastName}</Text>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <div style={{ paddingBottom: "10px" }}>
                                            <Text className="order-detail-baslik">{t("telefonnumaranız")}</Text>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <Text className="order-detail-text">{zel.phone}</Text>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <div style={{ paddingBottom: "10px" }}>
                                            <Text className="order-detail-baslik">{t("doğumgünü")}</Text>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <Text className="order-detail-text">{zel.birthday}</Text>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <div style={{ paddingBottom: "10px" }}>
                                            <Text className="order-detail-baslik">{t("tckimlik/password")}</Text>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <Text className="order-detail-text">{zel.citizenId}</Text>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Text className="order-detail-baslik">{t("email")}</Text>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                                        <Text className="order-detail-text">{zel.email}</Text>
                                    </Col>
                                </Row>
                            </div>
                        ))
                        : <></>}
                </div>
            </div>
        )
    }



    useEffect(() => {
        getMyAccount()
        getRezervation()

    }, [])

    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <Row gutter={[20, 20]} >
                            <Col span={24}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto" }}>
                                    <div className='search-top'>
                                        <Text className='account-baslik2'> {t("merhaba")},</Text>
                                        <Text className='account-baslik'>{myAccountData.firstName}</Text>
                                    </div>
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab={t("siparişlerim")} key="1">
                                            <Alert message={t("siparişinüzerinetıklayarakkişidetaylarınıgörebilirsiniz")} type="info" showIcon />
                                            <Row gutter={[20, 0]} className="tab1-root">
                                                <Collapse ghost className="my-orders" defaultActiveKey={['0']}>

                                                    {orderData.map((val, i) => (
                                                        <Panel key={i} header={
                                                            <Row gutter={[0, 0]} className='my-orders-tab' >
                                                                <Col>
                                                                    <Text className="order-baslik">{t("siparişoluşturmatarihi")}</Text>
                                                                </Col>
                                                                <Col>
                                                                    <Text className="order-text">{moment(val.cartItems[0].createdDateTime).format("LLLL")}</Text>
                                                                </Col>
                                                            </Row>
                                                        }  >
                                                            <Row gutter={[10, 10]}>
                                                                {val.cartItems.map((z, i) => (
                                                                    <Col key={i} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                                                                        <CartCard val={val} z={z} i={i} />
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </Panel>

                                                    ))}
                                                </Collapse>

                                            </Row>
                                        </TabPane>
                                        <TabPane tab={t("hesapbilgilerim")} key="2">
                                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "200px" }}>
                                                <Row style={{ paddingBottom: "10px" }}>
                                                    <Col>
                                                        <Text className="order-baslik">{t("adsoyad")} :</Text>
                                                    </Col>
                                                    <Col>
                                                        <Text className="order-text2">{myAccountData.firstName} {myAccountData.lastName}</Text>
                                                    </Col>
                                                </Row>
                                                <Row style={{ paddingBottom: "10px" }}>
                                                    <Col>
                                                        <Text className="order-baslik">{t("mailadresiniz")} :</Text>
                                                    </Col>
                                                    <Col>
                                                        <Text className="order-text">{myAccountData.email}</Text>
                                                    </Col>
                                                </Row>
                                                <Row style={{ paddingBottom: "10px" }}>
                                                    <Col>
                                                        <Text className="order-baslik">{t("hesapoluşturmatarihi")} :</Text>
                                                    </Col>
                                                    <Col>
                                                        <Text className="order-text">{moment(myAccountData.createdDateTime).format("LL")}</Text>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Info />
                </div>
            </div>
        </StickyContainer>
    )
}

export default MyAccount

