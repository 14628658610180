import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Input, notification, Row, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from "../../components/Card";
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import { FormRequest } from "../../api/controllers/contact-controller";

const ContactForm = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("contact-form")

    const { TextArea } = Input;

    const [showNav, setShowNav] = useState();
    const [contactForm, setContactForm] = useState({
        email: "",
        firstName: "",
        lastName: "",
        message: "",
        topic: ""
    })

    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };

    const submit = async () => {
        try {
            let res = await FormRequest(contactForm)
            if (res) {
                openNotification({ message: t("formgönderildi"), type: "success" })
                setContactForm({
                    email: "",
                    firstName: "",
                    lastName: "",
                    message: "",
                    topic: ""
                })
            }

        } catch (error) {
            error?.response?.data.map((val, i) =>
                openNotification({ message: val.message, type: "error" })
            )
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])

    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <Row gutter={[20, 20]} >
                            <Col span={24}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto" }}>
                                    <div className='search-top'>
                                        <Text className='kesfet-baslik'>{t("iletişimformu")}</Text>
                                    </div>

                                    <div className="rez-detail-middle">
                                        <Row gutter={[25, 25]} >
                                            <Col xs={24} sm={12} md={12} lg={12} xl={8} className="rez-detail-middle-item1">
                                                <Input value={contactForm.firstName} onChange={(e) => {
                                                    contactForm.firstName = e.target.value
                                                    setContactForm({ ...contactForm })
                                                }} className="rez-detail-middle-text2" placeholder={t("adınız")} />
                                                <Tooltip title={t("zorunlu")} >
                                                    <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                </Tooltip>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={8} className="rez-detail-middle-item1">
                                                <Input value={contactForm.lastName} onChange={(e) => {
                                                    contactForm.lastName = e.target.value
                                                    setContactForm({ ...contactForm })
                                                }} className="rez-detail-middle-text2" placeholder={t("soyadınız")} />
                                                <Tooltip title={t("zorunlu")} >
                                                    <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                </Tooltip>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={8} className="rez-detail-middle-item1">
                                                <Input value={contactForm.email} onChange={(e) => {
                                                    contactForm.email = e.target.value
                                                    setContactForm({ ...contactForm })
                                                }} className="rez-detail-middle-text2" placeholder={t("email")} />
                                                <Tooltip title={t("zorunlu")} >
                                                    <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                </Tooltip>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="rez-detail-middle-item1">

                                                <Input value={contactForm.topic} onChange={(e) => {
                                                    contactForm.topic = e.target.value
                                                    setContactForm({ ...contactForm })
                                                }} className="rez-detail-middle-text2" placeholder={t("konu")} />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="rez-detail-middle-item1">
                                                <TextArea value={contactForm.message} rows={4} onChange={(e) => {
                                                    contactForm.message = e.target.value
                                                    setContactForm({ ...contactForm })
                                                }} className="rez-detail-middle-text2" placeholder={t("mesaj")} />
                                                <Tooltip title={t("zorunlu")} >
                                                    <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                </Tooltip>
                                            </Col>
                                            <div style={{ width: "200px", paddingLeft: "20px" }}>
                                                <CustomButton onClick={submit} type="submit" text={t("formugönder")} colorType={"login-button-blue"} />
                                            </div>
                                        </Row>
                                    </div>


                                </div>
                            </Col>

                        </Row>
                    </div>
                    <Info />
                </div>
            </div>
        </StickyContainer>
    )
}

export default ContactForm

