import { LockOutlined, MailOutlined, UserOutlined, CloseCircleOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Input, Result, Row, Tabs, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from "../../components/Card";
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import { confirmTokenRequest } from "../../api/controllers/account-controller";

const VerificationPage = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("verification-page")

    const { TabPane } = Tabs;
    let navigate = useNavigate();
    const { Paragraph, Text } = Typography;

    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);


    function callback(key) {
    }
    var url = window.location;
    let token = new URLSearchParams(url.search).get('token');


    const verification = async () => {
        try {
            let res = await confirmTokenRequest(token)
            if (res?.status === 200) {
                setVisible(true)
            }

        } catch (error) {
            if (error?.response?.status === 400)
                setVisible2(true)
            /*  setTimeout(() => {
                 navigate("../")
             }, 1500); */
        }

    }

    useEffect(() => {
        verification()

    }, [])



    return (
        <div className="verification-page-root">
            {visible === true ?
                <div>
                    200 se gözükecek
                </div> : <></>}
            {visible2 === true ?
                <div>
                    <Result
                        status="error"
                        title="Submission Failed"
                        subTitle="Please check and modify the following information before resubmitting."
                        extra={[
                            <Button onClick={()=>navigate("../my-account")} type="primary" key="console">
                                Go Console
                            </Button>,
                            <Button onClick={()=>navigate("../shopping-cart")} key="buy">Buy Again</Button>,
                        ]}
                    >
                        <div className="desc">
                            <Paragraph>
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    The content you submitted has the following error:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account has been
                                frozen. <a>Thaw immediately &gt;</a>
                            </Paragraph>
                            <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" /> Your account is not yet
                                eligible to apply. <a>Apply Unlock &gt;</a>
                            </Paragraph>
                        </div>
                    </Result>
                </div> : <></>}
        </div>
    )
}

export default VerificationPage

