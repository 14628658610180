import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Button, Input, Modal, notification, Divider, Space, Select } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import StickyTop from "../../components/StickyTop";
import { useTranslation } from 'react-i18next';
import { RegisterRequest, verificationRequest } from "../../api/controllers/account-controller";
import countryList from 'react-select-country-list'



const Register = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("register")

    const Context = React.createContext({ name: 'Default' });
    let navigate = useNavigate();

    const [showNav, setShowNav] = useState();
    const [emailVerificationModal, setEmailVerificationModal] = useState(false)
    const [alertMessage, setAlertMessage] = useState("");

    const [verificationCode, setVerificationCode] = useState("")
    const [passwordControl, setpasswordControl] = useState("")
    const [registerData, setRegisterData] = useState([])
    const [isDisabled, setIsDisabled] = useState(false);



    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };


    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {
        registerData.countryCode = value
        setRegisterData({ ...registerData })
    }


    const register = async () => {
        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false)
        }, 1500);

        if (registerData.firstName && registerData.lastName /* && registerData.countryCode */ && registerData.password && registerData.email && passwordControl) {
            if (registerData.password !== passwordControl) {
                openNotification({ message: t("şifreleruyuşmuyor"), type: "warning" })
            }
            else {
                try {
                    let res = await RegisterRequest(registerData)
                    if (res) {
                        setEmailVerificationModal(true)
                        openNotification({ message: t("mailinizekodgönderildi"), type: "success" })
                    }
                }
                catch (error) {
                    error?.response?.data.map((val, i) =>
                        openNotification({ message: val.message, type: "error" })
                    )
                }
            }
        }
        else {
            openNotification({ message: t("değerlerboşbırakılamaz"), type: "error" })
        }
    }

    const verification = async () => {
        try {
            let res = await verificationRequest({ code: verificationCode, email: registerData.email })
            if (res?.status === 200) {
                openNotification({ message: t("başarıylakayıtolundu"), type: "success" })
                setTimeout(() => {
                    navigate("../login")
                }, 500);
            }

        } catch (error) {
            error?.response?.data.map((val, i) =>
                openNotification({ message: val.message, type: "error" })
            )
        }
    }


    return (
        <div className="login-root" style={{ backgroundImage: "url(/assets/img/kano.png)" }}>

            <StickyTop contentLeft={'sticky-top-text-blue'} contentRight={'sticky-top-text-blue'} setShowNav={setShowNav} />

            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <form onSubmit={register} className="login-root-right">
                <div className="register-root-right2">
                    <div className="login-right-top">
                        <Text className="login-right-top1">{t("üye")}</Text>
                        <Text className="login-right-top2"> {t("olun")}</Text>
                    </div>
                    <div>
                        <Input onChange={(e) => {
                            registerData.firstName = e.target.value
                            setRegisterData({ ...registerData })
                        }} className="login-right-middle1" size="large" placeholder={t("adınız")} prefix={<img src='/assets/img/human.svg' />} />
                        <div style={{ width: "100%", border: "1px solid rgba(44, 62, 80, 0.5)" }}></div>
                    </div>
                    <div>
                        <Input onChange={(e) => {
                            registerData.lastName = e.target.value
                            setRegisterData({ ...registerData })
                        }} className="login-right-middle1" size="large" placeholder={t("soyadınız")} prefix={<img src='/assets/img/human.svg' />} />
                        <div style={{ width: "100%", border: "1px solid rgba(44, 62, 80, 0.5)" }}></div>
                    </div>
                    {/*  <div className="country-input">
                            <img style={{ paddingLeft: "10px" }} src="/assets/img/bayrak.svg" />
                            <Select showSearch placeholder="ülkeniz" className="login-right-middle1"
                                options={options} onChange={(e) => changeHandler(e)}
                                style={{ width: '100%', border: "1px solid lightgrey", color: "black" }} />
                            <Input onChange={(e) => {
                                    registerData.countryCode = e.target.value
                                    setRegisterData({ ...registerData })
                                }}  size="large"  />
                        </div>
                        <div style={{ width: "100%", border: "1px solid rgba(44, 62, 80, 0.5)" }}></div> */}
                    <div>
                        <Input onChange={(e) => {
                            registerData.email = e.target.value
                            setRegisterData({ ...registerData })
                        }} className="login-right-middle1" size="large" placeholder={t("mailadresiniz")} prefix={<img src='/assets/img/mail.svg' />} />
                        <div style={{ width: "100%", border: "1px solid rgba(44, 62, 80, 0.5)" }}></div>
                    </div>
                    <div>
                        <Input.Password className="login-right-middle1" onChange={(e) => {
                            registerData.password = e.target.value
                            setRegisterData({ ...registerData })
                        }} size="large" placeholder={t("şifreniz")} prefix={<img src='/assets/img/password.svg' />} />
                        <div style={{ width: "100%", border: "1px solid rgba(44, 62, 80, 0.5)" }}></div>
                    </div>
                    <div>
                        <Input.Password onChange={(e) => setpasswordControl(e.target.value)} className="login-right-middle1" size="large" placeholder={t("şifreniztekrar")} prefix={<img src='/assets/img/password.svg' />} />
                        <div style={{ width: "100%", border: "1px solid rgba(44, 62, 80, 0.5)", marginBottom: "20px" }}></div>
                    </div>
                    <div className="login-buttons">
                        <Button htmlType='submit' loading={isDisabled} disabled={isDisabled} onClick={register} className="login-button-blue">{t("üyeol")}</Button>
                        <div className="login-default-text" >{t("veya")}</div>
                        <Button onClick={() => navigate('../login')} className="login-button-white">{t("girişyap")}</Button>
                    </div>
                </div>

            </form>
            <Modal
                title={t("emailkoddoğrulama")}
                visible={emailVerificationModal}
                onOk={verification}
                maskClosable={false}
                onCancel={() => window.location.reload()}
                width={200}
                className='verification-modal'
            >
                <Input onChange={(e) => {
                    setVerificationCode(e.target.value)
                }} className="login-right-middle3" size="large" placeholder={t("aktivasyonkodu")} prefix={<img src='/assets/img/mail.svg' />} />
            </Modal>
        </div>
    )
}

export default Register