import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import './global.css';
import App from './App';
import './i18n';
import { ConfigProvider } from "antd";
import trTR from 'antd/lib/locale/tr_TR';
import enUS from 'antd/lib/locale/en_US';


ConfigProvider.config({
  theme: {
    primaryColor: '#385BA6',
  },
});

ReactDOM.render(
  <React.Fragment>
    <ConfigProvider locale={enUS}>
      <Suspense fallback={<div className="loading-screen"><img className="loading-image"  src='/assets/img/LOGO-DARK.svg' /></div>}>
        <App />
      </Suspense>
    </ConfigProvider>
  </React.Fragment>,
  document.getElementById('root'))


