import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Select, Tooltip } from 'antd';
import { SideNav } from 'react-simple-sidenav';
import Text from 'antd/lib/typography/Text';
import { CloseOutlined, LogoutOutlined, ShoppingCartOutlined } from '@ant-design/icons/lib/icons';
import CustomButton from './Buttons';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getMeRequest } from '../api/controllers/account-controller';
import { getCategoryRequest } from '../api/controllers/category-controller';

const NavBar = ({ showNav, onHideNav, setShowNav }) => {
    const { t, i18n } = useTranslation("common");
    let navigate = useNavigate();
    const [role, setRole] = useState("")
    const [categoryData, setCategoryData] = useState([])
    const [newData, setNewData] = useState([])

    const checkAuthentication = async () => {
        try {
            let res = await getMeRequest()
            if (res?.status === 200 && res?.data?.role === "ROLE_ADMIN") {
                setRole("admin")
            } else if (res?.status === 200 && res?.data?.role === "ROLE_USER") {
                setRole("user")
            }
        } catch (error) {
        }
    }

    const logout = () => {
        localStorage.removeItem('token');
        navigate("../")
        window.location.reload()
    }

    const fetchCategory = async () => {

        try {
            let res = await getCategoryRequest()
            if (res) {
                setCategoryData(res.data)
            }
        } catch (error) {
        }
    }

    useEffect(() => {

        checkAuthentication()
        fetchCategory()

    }, [])

    return (
        <SideNav
            showNav={showNav}
            onHideNav={onHideNav}
            items={['HOME', 'ADVENTURE TOURS', 'BOAT TOURS', 'CULTURE TOURS', 'HOTELS', 'VILLAS', 'CONTACT']}
            titleStyle={{ backgroundColor: "#232323", fontWeight: "300", fontSize: "2rem" }}
            itemHoverStyle={{ backgroundColor: '#232323' }}
            itemStyle={{ backgroundColor: '#232323', color: "#fff", }}
            navStyle={{ backgroundColor: '#2C3E50', width: "100%", maxWidth: "450px" }}
            style={{ zIndex: "1051" }}
            openFromRight={true}
        >
            <div style={{ display: "flex", flexDirection: "column", padding: "30px", height: "100vh", justifyContent: "space-between", }}>
                <div >
                    <div className='navbar-top'>
                        <Text style={{ color: "#fff", fontSize: "2rem", fontWeight: "400" }}>
                            MENU
                        </Text>
                        <CloseOutlined onClick={() => setShowNav(false)} className='closeicon' />
                    </div>


                    {role ?

                        <Row gutter={[10, 10]} className='side-nav-top' >
                            <Col span={12}>
                                <CustomButton onClick={() => navigate('../my-account')} text={t("profilim")} colorType={"login-button-white-borderless"} />
                            </Col>
                            <Col span={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <div style={{ border: "1px solid black", width: "1px", height: "100%" }}></div>
                            </Col>
                            <Col span={11} className='logout-user'>
                                <Tooltip title={t("çıkışyap")}>
                                    <Button onClick={logout} style={{ backgroundColor: "transparent", color: "rgba(126, 3, 3)", border: "none" }} type="primary" shape="circle" icon={<LogoutOutlined />} />
                                </Tooltip>
                            </Col>
                        </Row>

                        :
                        <Row gutter={[10, 10]} className='side-nav-top'>
                            <Col span={11}>
                                <CustomButton onClick={() => navigate('../login')} text={t("girişyap")} colorType={"login-button-white-borderless"} />
                            </Col>
                            <Col span={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <div style={{ border: "1px solid black", width: "1px", height: "100%" }}></div>
                            </Col>
                            <Col span={11}>
                                <CustomButton onClick={() => navigate('../register')} text={t("üyeol")} colorType={"login-button-white-borderless"} />
                            </Col>
                        </Row>
                    }


                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>

                        <Text onClick={() => navigate('../')} className='navbar-inside'>
                            {t("anasayfa")}
                        </Text>

                        {categoryData.slice(0, 3).map((val, i) => (
                            <Text onClick={() => navigate(`../search?id=${val?.id}&city=`)} key={i} className='navbar-inside'>
                                {val?.title}
                            </Text>
                        ))}


                        {/*   <Text onClick={() => navigate(`../search?id=23&city=`)} className='navbar-inside'>
                            {t("maceraturları")}
                        </Text>
                        <Text onClick={() => navigate(`../search?id=1&city=`)} className='navbar-inside'>
                            {t("botturları")}
                        </Text>
                        <Text onClick={() => navigate(`../search?id=24&city=`)} className='navbar-inside'>
                            {t("kültürturları")}
                        </Text> */}
                        {/*   <Text className='navbar-inside'>
                            {t("paketturlar")}
                        </Text> */}
                        <Text onClick={() => window.open("https://www.otelz.com?to=1876&cid=222")} className='navbar-inside'>
                            {t("oteller")}
                        </Text>
                        <Text onClick={() => navigate("../flight-reservation")} className='navbar-inside'>
                            {t("uçakrezervasyonu")}
                        </Text>
                        <Text onClick={() => window.open("https://www.gezginevivillas.com/")} className='navbar-inside'>
                            {t("villalar")}
                        </Text>
                        <Text onClick={() => navigate("../contact-form")} className='navbar-inside'>
                            {t("iletişim2")}
                        </Text>
                    </div>
                </div>
                <div style={{ borderTop: "1px solid rgba(255, 255, 255, 0.1)", paddingTop: "20px" }}>
                    <img className='logo-navbar' src='/assets/img/LOGO-LIGHT-FRAME.svg' />
                </div>
            </div>

        </SideNav>
    )
}
export default NavBar;


