import { CalendarOutlined } from "@ant-design/icons/lib/icons";
import { Col, Input, Row, DatePicker, Space, notification, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { getActivitybyId } from "../../api/controllers/activity-controller";
import NumberFormat from 'react-number-format';

const RezDetail = () => {
    const { t } = useTranslation("common");
    document.title = 'tripgoX | ' + t("rez-detail")

    let navigate = useNavigate();
    var url = window.location;
    let id = new URLSearchParams(url.search).get('id');
    let bc = new URLSearchParams(url.search).get('bc');
    let cc = new URLSearchParams(url.search).get('cc');
    let ac = new URLSearchParams(url.search).get('ac');
    let bp = new URLSearchParams(url.search).get('bp');
    let cp = new URLSearchParams(url.search).get('cp');
    let ap = new URLSearchParams(url.search).get('ap');
    let total = parseInt(bc) + parseInt(cc) + parseInt(ac - 1)
    let totalPrice = parseInt(bc) * parseInt(bp) + parseInt(ac) * parseInt(ap) + parseInt(cc) * parseInt(cp)
    let cart = JSON.parse(localStorage.getItem("rezervationInfo") || "[]");

    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };

    const [control, setControl] = useState({
        control1: "",
    })
    const [showNav, setShowNav] = useState();
    const [priceData, setPriceData] = useState()
    const [adultData, setAdultData] = useState([])
    const [childData, setChildData] = useState([])
    const [infantData, setInfantData] = useState([])
    const [sideData, setSideData] = useState([])
    const [travelerList, setTravelerList] = useState({
        adultData: adultData,
        childData: childData,
        infantData: infantData
    })
    const [firstData, setFirstData] = useState({
        citizenId: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        birthday: null,
        hotelName: null,
        hotelRoom: null,
        travelerType: "Adult"
    })

    const [rezervationInfo, setRezervationInfo] = useState({
        activityId: id,
        activityDate: null,
        travelerList: travelerList,
        sideData: sideData
    })

    const createAdultDataList = () => {
        for (let index = 0; index < parseInt(ac - 1); index++) {
            adultData.push({
                citizenId: null,
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
                birthday: null,
                travelerType: "Adult"
            })
            setAdultData([...adultData])
        }
    }

    const createChildDataList = () => {
        for (let index = 0; index < cc; index++) {
            childData.push({
                citizenId: null,
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
                birthday: null,
                travelerType: "Child"
            })
            setChildData([...childData])
        }
    }

    const createInfantDataList = () => {
        for (let index = 0; index < bc; index++) {
            infantData.push({
                citizenId: null,
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
                birthday: null,
                travelerType: "Infant"
            })
            setInfantData([...infantData])
        }
    }


    const handleCreate = async () => {

        if (rezervationInfo.activityDate === null) {
            openNotification({ message: t("aktivitetarihiboşbırakılamaz"), type: "warning" })
            control.control5 = false
            setControl({ ...control })
        } else if (rezervationInfo.activityDate === "") {
            openNotification({ message: t("aktivitetarihiboşbırakılamaz"), type: "warning" })
            control.control5 = false
            setControl({ ...control })
        } else {
            control.control5 = true
            setControl({ ...control })
        }
        if (Object.values(firstData).includes(null)) {
            openNotification({ message: t("rezervasyonyaptırankişibilgileriboşolamaz"), type: "warning" })
            control.control1 = false
            setControl({ ...control })
        } else if (Object.values(firstData).includes("")) {
            openNotification({ message: t("rezervasyonyaptırankişibilgileriboşolamaz"), type: "warning" })
            control.control1 = false
            setControl({ ...control })
        } else if (Object.values(firstData?.birthday).includes("_")) {
            openNotification({ message: t("doğumtarihinikontrolediniz"), type: "warning" })
            control.control1 = false
            setControl({ ...control })
        } else if (firstData?.birthday?.split(/[-]/g)[0] < 1923) {
            openNotification({ message: t("doğumtarihi1923tenküçükolamaz"), type: "warning" })
            control.control1 = false
            setControl({ ...control })
        } else if (firstData?.birthday?.split(/[-]/g)[0] > new Date().getFullYear()) {
            openNotification({ message: t("doğumtarihigünümüzdenbüyükolamaz"), type: "warning" })
            control.control1 = false
            setControl({ ...control })
        } else if (firstData?.birthday?.split(/[-]/g)[1] > 12 || firstData?.birthday?.split(/[-]/g)[1] < 1) {
            openNotification({ message: t("ay1ile12arasındaolmalıdır"), type: "warning" })
            control.control1 = false
            setControl({ ...control })
        } else if (firstData?.birthday?.split(/[-]/g)[2] > 31 || firstData?.birthday?.split(/[-]/g)[2] < 1) {
            openNotification({ message: t("gün1ile31arasındaolmalıdır"), type: "warning" })
            control.control1 = false
            setControl({ ...control })
        } else {
            control.control1 = true
            setControl({ ...control })
        }
        if (travelerList.adultData.length > 0) {
            adultData.forEach(function (v, i) {
                if (
                    Object.keys(v).some(function (k) {
                        return v[k] === null;
                    }) || Object.keys(v).some(function (k) {
                        return v[k] === '';
                    })
                ) {
                    openNotification({ message: t("yetişkinziyaretçibilgilerinikontoledin"), type: "warning" })
                    control.control2 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[0] < 1923) {
                    openNotification({ message: t("doğumtarihi1923tenküçükolamaz"), type: "warning" })
                    control.control2 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[0] > new Date().getFullYear()) {
                    openNotification({ message: t("doğumtarihigünümüzdenbüyükolamaz"), type: "warning" })
                    control.control2 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[1] > 12 || v["birthday"]?.split(/[-]/g)[1] < 1) {
                    openNotification({ message: t("ay1ile12arasındaolmalıdır"), type: "warning" })
                    control.control2 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[2] > 31 || v["birthday"]?.split(/[-]/g)[2] < 1) {
                    openNotification({ message: t("gün1ile31arasındaolmalıdır"), type: "warning" })
                    control.control2 = false
                    setControl({ ...control })
                } else if (Object.values(v["birthday"]).includes("_")) {
                    openNotification({ message: t("doğumtarihinikontrolediniz"), type: "warning" })
                    control.control2 = false
                    setControl({ ...control })
                }
                else {
                    control.control2 = true
                    setControl({ ...control })
                }
            })
        } else {
            control.control2 = true
            setControl({ ...control })
        }
        if (childData.length > 0) {
            childData.forEach(element => {
                if (element.phone === null) {
                    element.phone = firstData.phone
                }
                else if (element.phone === "") {
                    element.phone = firstData.phone
                }
                if (element.email === null) {
                    element.email = firstData.email
                }
                else if (element.email === "") {
                    element.email = firstData.email
                }
            })
            childData.forEach(function (v, i) {
                if (
                    // get all properties and check any of it's value is null
                    Object.keys(v).some(function (k) {
                        return v[k] === null;
                    }) || Object.keys(v).some(function (k) {
                        return v[k] === '';
                    })
                ) {
                    openNotification({ message: t("çocukziyaretçibilgilerinikontoledin"), type: "warning" })
                    control.control3 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[0] < 1923) {
                    openNotification({ message: t("doğumtarihi1923tenküçükolamaz"), type: "warning" })
                    control.control3 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[0] > new Date().getFullYear()) {
                    openNotification({ message: t("doğumtarihigünümüzdenbüyükolamaz"), type: "warning" })
                    control.control3 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[1] > 12 || v["birthday"]?.split(/[-]/g)[1] < 1) {
                    openNotification({ message: t("ay1ile12arasındaolmalıdır"), type: "warning" })
                    control.control3 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[2] > 31 || v["birthday"]?.split(/[-]/g)[2] < 1) {
                    openNotification({ message: t("gün1ile31arasındaolmalıdır"), type: "warning" })
                    control.control3 = false
                    setControl({ ...control })
                } else if (Object.values(v["birthday"]).includes("_")) {
                    openNotification({ message: t("doğumtarihinikontrolediniz"), type: "warning" })
                    control.control3 = false
                    setControl({ ...control })
                }
                else {
                    control.control3 = true
                    setControl({ ...control })
                }
            })
        } else {
            control.control3 = true
            setControl({ ...control })
        }
        if (infantData.length > 0) {
            infantData.forEach(element => {
                if (element.phone === null) {
                    element.phone = firstData.phone
                }
                else if (element.phone === "") {
                    element.phone = firstData.phone
                }
                if (element.email === null) {
                    element.email = firstData.email
                }
                else if (element.email === "") {
                    element.email = firstData.email
                }
            })
            infantData.forEach(function (v, i) {
                if (
                    // get all properties and check any of it's value is null
                    Object.keys(v).some(function (k) {
                        return v[k] === null;
                    }) || Object.keys(v).some(function (k) {
                        return v[k] === '';
                    })
                ) {
                    openNotification({ message: t("bebekziyaretçibilgilerinikontoledin"), type: "warning" })
                    control.control4 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[0] < 1923) {
                    openNotification({ message: t("doğumtarihi1923tenküçükolamaz"), type: "warning" })
                    control.control4 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[0] > new Date().getFullYear()) {
                    openNotification({ message: t("doğumtarihigünümüzdenbüyükolamaz"), type: "warning" })
                    control.control4 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[1] > 12 || v["birthday"]?.split(/[-]/g)[1] < 1) {
                    openNotification({ message: t("ay1ile12arasındaolmalıdır"), type: "warning" })
                    control.control4 = false
                    setControl({ ...control })
                } else if (v["birthday"]?.split(/[-]/g)[2] > 31 || v["birthday"]?.split(/[-]/g)[2] < 1) {
                    openNotification({ message: t("gün1ile31arasındaolmalıdır"), type: "warning" })
                    control.control4 = false
                    setControl({ ...control })
                } else if (Object.values(v["birthday"]).includes("_")) {
                    openNotification({ message: t("doğumtarihinikontrolediniz"), type: "warning" })
                    control.control4 = false
                    setControl({ ...control })
                }
                else {
                    control.control4 = true
                    setControl({ ...control })
                }
            })
        } else {
            control.control4 = true
            setControl({ ...control })
        }
        if (control.control1 === true && control.control2 === true && control.control3 === true && control.control4 === true && control.control5 === true) {
            rezervationInfo?.sideData?.push(sideData)
            travelerList?.adultData?.push(firstData)
            cart.push(rezervationInfo)
            localStorage.setItem("rezervationInfo", JSON.stringify(cart))
            localStorage.setItem("firstData", JSON.stringify(firstData))
            navigate("../shopping-cart")
            openNotification({ message: t("sepeteeklemebaşarılı"), type: "success" })
        }
    }

    function onChange(date, dateString) {
        rezervationInfo.activityDate = dateString
    }

    const fetchActivity = async () => {
        try {
            let res = await getActivitybyId(id)
            if (res) {
                setPriceData(res.data)
                setSideData({
                    city: res.data.city,
                    title: res.data.title,
                    totalPrice: totalPrice
                })
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        createAdultDataList()
        createChildDataList()
        createInfantDataList()
        fetchActivity()
        window.scrollTo(0, 0)

    }, [])

    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <Row gutter={[20, 20]} >
                            <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={18}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto" }}>
                                    <div className='search-top'>
                                        <Text className='kesfet-baslik'>{t("rezervasyondetayları")}</Text>
                                    </div>
                                    <div className='rez-detail-root' >
                                        <Text className='rez-detail-top'>
                                            {sideData.title} / {sideData.city}
                                        </Text>
                                        <Row style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }} >
                                            <Col xs={24} sm={14} md={24} lg={14} xl={16} >
                                                <Text className="rez-detail-middle-text3">
                                                    {t("rezervasyonyaptırankişi")}
                                                </Text>
                                            </Col>
                                            <Col xs={24} sm={10} md={24} lg={10} xl={8} >
                                                <Space className="rez-detail-date" direction="vertical">
                                                    <DatePicker
                                                        suffixIcon={<div>
                                                            <CalendarOutlined />
                                                            <Tooltip title={t("zorunlu")} >
                                                                <img alt='' style={{ width: "5px", paddingBottom: "15px" }} src='/assets/img/required.svg' />
                                                            </Tooltip>
                                                        </div>}
                                                        inputReadOnly={true}
                                                        placeholder={t("aktivitetarihi")}
                                                        disabledDate={(current) => {
                                                            let customDate = moment().format("YYYY-MM-DD");
                                                            return current && current < moment(customDate, "YYYY-MM-DD");
                                                        }}
                                                        onChange={onChange} />
                                                </Space>
                                            </Col>
                                        </Row>


                                        <div className="rez-detail-middle">
                                            <Row gutter={[25, 25]} >
                                                <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                    <NumberFormat allowClear customInput={Input} onChange={(e) => {
                                                        firstData.citizenId = e.target.value
                                                        setFirstData({ ...firstData })
                                                    }} className="rez-detail-middle-text2" placeholder={t("tckimlik/password")} />
                                                    <Tooltip title={t("zorunlu")} >
                                                        <img alt='' style={{ width: "5px", paddingBottom: "25px" }} src='/assets/img/required.svg' />
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                    <Input allowClear onChange={(e) => {
                                                        firstData.firstName = e.target.value
                                                        setFirstData({ ...firstData })
                                                    }} className="rez-detail-middle-text2" placeholder={t("adınız")} />
                                                    <Tooltip title={t("zorunlu")} >
                                                        <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                    <Input allowClear onChange={(e) => {
                                                        firstData.lastName = e.target.value
                                                        setFirstData({ ...firstData })
                                                    }} className="rez-detail-middle-text2" placeholder={t("soyadınız")} />
                                                    <Tooltip title={t("zorunlu")} >
                                                        <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                    <NumberFormat allowClear customInput={Input} onChange={(e) => {
                                                        firstData.phone = e.target.value
                                                        setFirstData({ ...firstData })
                                                    }} className="rez-detail-middle-text2" placeholder={t("telefonnumaranız")} />
                                                    <Tooltip title={t("zorunlu")} >
                                                        <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                    <Input allowClear onChange={(e) => {
                                                        firstData.email = e.target.value
                                                        setFirstData({ ...firstData })
                                                    }} className="rez-detail-middle-text2" placeholder={t("email")} />
                                                    <Tooltip title={t("zorunlu")} >
                                                        <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                    <Input allowClear onChange={(e) => {
                                                        firstData.hotelName = e.target.value
                                                        setFirstData({ ...firstData })
                                                    }} className="rez-detail-middle-text2" placeholder={t("oteladı")} />
                                                    <Tooltip title={t("zorunlu")} >
                                                        <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                    <Input allowClear onChange={(e) => {
                                                        firstData.hotelRoom = e.target.value
                                                        setFirstData({ ...firstData })
                                                    }} className="rez-detail-middle-text2" placeholder={t("odanumarası")} />
                                                    <Tooltip title={t("zorunlu")} >
                                                        <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                    <NumberFormat mask="_" format={"##-##-####"} allowClear customInput={Input} onChange={(e) => {
                                                        firstData.birthday = e.target.value.split('-').reverse().join('-')
                                                        setFirstData({ ...firstData })
                                                    }} className="rez-detail-middle-text2" placeholder={t("doğumgünü")} />
                                                    <Tooltip title={t("zorunlu")} >
                                                        <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </div>
                                        {ac > 1 ? <Text className="rez-detail-middle-text1">
                                            {t("diğeryetişkinziyaretçiler")}
                                        </Text> : <></>}

                                        {adultData.map((val, i) => (
                                            <div key={i} className="rez-detail-middle" >
                                                <Row gutter={[25, 25]} >
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat allowClear customInput={Input} onChange={(e) => {
                                                            adultData[i].citizenId = e.target.value
                                                            setAdultData([...adultData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("tckimlik/password")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            adultData[i].firstName = e.target.value
                                                            setAdultData([...adultData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("adınız")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            adultData[i].lastName = e.target.value
                                                            setAdultData([...adultData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("soyadınız")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat allowClear customInput={Input} onChange={(e) => {
                                                            adultData[i].phone = e.target.value
                                                            setAdultData([...adultData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("telefonnumaranız")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            adultData[i].email = e.target.value
                                                            setAdultData([...adultData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("email")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat mask="_" format={"##-##-####"} allowClear customInput={Input} onChange={(e) => {
                                                            adultData[i].birthday = e.target.value.split('-').reverse().join('-')
                                                            setAdultData([...adultData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("doğumgünü")} />
                                                        {/*  <DatePicker
                                                            inputReadOnly={true}
                                                            placeholder={t("doğumgünü")}
                                                            disabledDate={(current) => {
                                                                let customDate = moment().format("YYYY-MM-DD");
                                                                return current && current > moment(customDate, "YYYY-MM-DD");
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                adultData[i].birthday = dateString
                                                                setAdultData([...adultData])
                                                            }} /> */}
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </div >
                                        ))}
                                        {cc > 0 ? <Text className="rez-detail-middle-text1">
                                            {t("çocukziyaretçiler")}
                                        </Text> : <></>}

                                        {childData.map((val, i) => (
                                            <div key={i} className="rez-detail-middle" >
                                                <Row gutter={[25, 25]} >
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat allowClear customInput={Input} onChange={(e) => {
                                                            childData[i].citizenId = e.target.value
                                                            setChildData([...childData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("tckimlik/password")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            childData[i].firstName = e.target.value
                                                            setChildData([...childData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("adınız")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            childData[i].lastName = e.target.value
                                                            setChildData([...childData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("soyadınız")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat allowClear customInput={Input} onChange={(e) => {
                                                            childData[i].phone = e.target.value
                                                            setChildData([...childData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("telefonnumaranız")} />
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            childData[i].email = e.target.value
                                                            setChildData([...childData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("email")} />
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat mask="_" format={"##-##-####"} allowClear customInput={Input} onChange={(e) => {
                                                            childData[i].birthday = e.target.value.split('-').reverse().join('-')
                                                            setChildData([...childData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("doğumgünü")} />
                                                        {/*  <DatePicker
                                                            inputReadOnly={true}
                                                            placeholder={t("doğumgünü")}
                                                            disabledDate={(current) => {
                                                                let customDate = moment().format("YYYY-MM-DD");
                                                                return current && current > moment(customDate, "YYYY-MM-DD");
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                childData[i].birthday = dateString
                                                                setChildData([...childData])
                                                            }} /> */}
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </div >
                                        ))}
                                        {bc > 0 ? <Text className="rez-detail-middle-text1">
                                            {t("bebekziyaretçiler")}
                                        </Text> : <></>}

                                        {infantData.map((val, i) => (
                                            <div key={i} className="rez-detail-middle" >
                                                <Row gutter={[25, 25]} >
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat allowClear customInput={Input} onChange={(e) => {
                                                            infantData[i].citizenId = e.target.value
                                                            setInfantData([...infantData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("tckimlik/password")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            infantData[i].firstName = e.target.value
                                                            setInfantData([...infantData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("adınız")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            infantData[i].lastName = e.target.value
                                                            setInfantData([...infantData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("soyadınız")} />
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat allowClear customInput={Input} onChange={(e) => {
                                                            infantData[i].phone = e.target.value
                                                            setInfantData([...infantData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("telefonnumaranız")} />
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <Input allowClear onChange={(e) => {
                                                            infantData[i].email = e.target.value
                                                            setInfantData([...infantData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("email")} />
                                                    </Col>
                                                    <Col xs={24} sm={12} md={24} lg={12} xl={8} className="rez-detail-middle-item1">
                                                        <NumberFormat mask="_" format={"##-##-####"} allowClear customInput={Input} onChange={(e) => {
                                                            infantData[i].birthday = e.target.value.split('-').reverse().join('-')
                                                            setInfantData([...infantData])
                                                        }} className="rez-detail-middle-text2" placeholder={t("doğumgünü")} />
                                                        {/*   <DatePicker
                                                            inputReadOnly={true}
                                                            placeholder={t("doğumgünü")}
                                                            disabledDate={(current) => {
                                                                let customDate = moment().format("YYYY-MM-DD");
                                                                return current && current > moment(customDate, "YYYY-MM-DD");
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                infantData[i].birthday = dateString
                                                                setInfantData([...infantData])
                                                            }} /> */}
                                                        <Tooltip title={t("zorunlu")} >
                                                            <img alt='' style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </div >
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={6}>
                                <Rezervation priceData={priceData} handleCreate={handleCreate} whiteText={"sepeteekle"} />
                            </Col>
                        </Row>
                    </div>
                    <Info />
                </div>
            </div>
        </StickyContainer>
    )
}

export default RezDetail

