import { Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import './AdminPanel.css';
import { useNavigate } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';


const HomePageAdmin = () => {

  return (
    <div >
      <Text>ANA SAYFA</Text>
    </div>
  )
}


export default HomePageAdmin;

