import axios from "axios"
import { useMemo } from "react"


export const BASE_URL = "https://tripgox.com"
//export const BASE_URL = "http://10.10.10.252:8081"
//export const BASE_URL = "http://10.10.10.88:8081"
//export const BASE_URL = "http://localhost:8081"


axios.defaults.baseURL = `${BASE_URL}/api/v1`
axios.defaults.withCredentials = false

const ApiProvider = ({ children }) => {
    useMemo(() => {
        axios.interceptors.request.use(async (config) => {

            const token = localStorage.getItem("token")

            if (token && token !== null) {
                config.headers["Authorization"] = `Bearer ${token}`
            }
            config.headers["Language"] = localStorage.getItem('currentLanguage')
            config.headers["Currency"] = localStorage.getItem('currentCurrency')

            return config
        }, async () => {
        });
        axios.interceptors.response.use(async (response) => {

            // if (response.status === 401) {
            //     window.location.href = "/login"
            //     return response
            // }

            if (response?.data?.token) {
                localStorage.setItem("token", response?.data?.token)
            }

            return response;
        }

        );
    }, [])
    return children
}



export default ApiProvider