import { Button, Layout, Menu, Tooltip } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    SearchOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import './AdminPanel.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';

const { Header, Sider, Content } = Layout;

const AdminPanelLayout = ({ children, contentLeft }) => {
    let navigate = useNavigate();
    const location = useLocation()

    const [collapsed, setCollapsed] = useState(false)

    const toggle = () => {

        setCollapsed(!collapsed)

    };

    const logout = () => {
        localStorage.removeItem('token');
        navigate("/")
    }




    return (
        <Layout className='admin-root' >
            <Sider trigger={null} collapsible collapsed={collapsed}>
                {collapsed === false ? <div onClick={() => navigate('../admin/home-page')} style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                    <img className='logo-navbar' src='/assets/img/LOGO-LIGHT-FRAME.svg' />
                </div>
                    :
                    <div onClick={() => navigate('../admin/home-page')} style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", padding: "20px 0 20px 0" }}>
                        <img className='logo-navbar-admin2' src='/assets/img/LOGO-LIGHT-FRAME.svg' />
                    </div>}
                <Menu theme="dark" mode="inline" defaultSelectedKeys={location.pathname}>
                    <Menu.Item onClick={() => navigate("/admin/home-page")} key="/admin/home-page" icon={<UserOutlined />}>
                        Ana Sayfa
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/admin/category")} key="/admin/category" icon={<UploadOutlined />}>
                        Kategori Ekle
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/admin/categoryView")} key="/admin/categoryView" icon={<VideoCameraOutlined />}>
                        Kategoriler
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/admin/activity")} key="/admin/activity" icon={<UploadOutlined />}>
                        Aktivite Ekle
                    </Menu.Item>
                    <Menu.Item onClick={() => navigate("/admin/activityView")} key="/admin/activityView" icon={<VideoCameraOutlined />}>
                        Aktiviteler
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ paddingLeft: "20px", display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: () => toggle(),
                    })}
                    <div className='logout'>
                        <Tooltip title="Çıkış Yap">
                            <Button onClick={logout} style={{ backgroundColor: "rgba(126, 3, 3, 0.1)", border: "none", color: "rgba(126, 3, 3, 1)" }} type="primary" shape="circle" icon={<LogoutOutlined />} />
                        </Tooltip>
                    </div>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}


export default AdminPanelLayout

