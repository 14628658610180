import { render } from "react-dom";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import App from "../App";
import AdminPanel from "../pages/admin/AdminPanelLayout";
import HomePage from "../pages/app/HomePage";
import AboutUs from "../pages/app/AboutUs";
import Detail from "../pages/app/Detail";
import Login from "../pages/app/Login";
import MyAccount from "../pages/app/MyAccount";
import Register from "../pages/app/Register";
import RezDetail from "../pages/app/RezDetail";
import Search from "../pages/app/Search";
import AdminRouter from "./AdminRouter";
import { Fragment } from "react";
import AdminPanel3 from "../pages/admin/Activity";
import Category from "../pages/admin/Category";
import Activity from "../pages/admin/Activity";
import HomePageAdmin from "../pages/admin/HomePageAdmin";
import CategoryView from "../pages/admin/CategoryView";
import ActivityView from "../pages/admin/ActivityView";
import VerificationPage from "../pages/app/VerificationPage";
import PaymentPage from "../pages/app/PaymentPage";
import ShoppingCart from "../pages/app/ShoppingCart";
import DiscoverMore from "../pages/app/DiscoverMore";
import DistanceSalesAgreement from "../pages/app/DistanceSalesAgreement";
import DeliveryCancellationandRefund from "../pages/app/DeliveryCancellationandRefund";
import TermsOfService from "../pages/app/TermsOfService";
import ContactForm from "../pages/app/ContactForm";
import FlightReservation from "../pages/app/FlightReservation";
import PrivacyAndSecurity from "../pages/app/PrivacyAndSecurity";


// import your route components too

const Router = () => {
    return (
        <BrowserRouter>
            <Fragment>
                <Routes>

                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/detail" element={<Detail />} />
                    <Route path="/rez-detail" element={<RezDetail />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact-form" element={<ContactForm />} />
                    <Route path="/flight-reservation" element={<FlightReservation />} />
                    <Route path="/distance-sales-contract" element={<DistanceSalesAgreement />} />
                    <Route path="/delivery-cancellation-and-refund" element={<DeliveryCancellationandRefund />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/privacy-and-security" element={<PrivacyAndSecurity />} />
                    <Route path="/discover-more" element={<DiscoverMore />} />
                    <Route path="/my-account" element={<MyAccount />} />
                    <Route path="/verification-page" element={<VerificationPage />} />
                    <Route path="/payment-page" element={<PaymentPage />} />
                    <Route path="/shopping-cart" element={<ShoppingCart />} />


                    <Route exact path="/admin" element={<AdminRouter />} >
                        <Route path="/admin/home-page" element={<HomePageAdmin />} />
                        <Route path="/admin/category" element={<Category />} />
                        <Route path="/admin/categoryView" element={<CategoryView />} />
                        <Route path="/admin/activityView" element={<ActivityView />} />
                        <Route path="/admin/activity" element={<Activity />} />
                    </Route>


                </Routes>
            </Fragment>
        </BrowserRouter>
    )
}

export default Router
