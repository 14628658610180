import { useState, useEffect, Fragment } from "react"
import { Outlet, Redirect, Route } from "react-router-dom"
import { getMeRequest } from "../api/controllers/account-controller"
import AdminPanelLayout from "../pages/admin/AdminPanelLayout"


const AdminRouter = () => {

    const [AUTH_STATUS, setAUTH_STATUS] = useState("WAITING")



    const checkAuthentication = async () => {

        try {
            let res = await getMeRequest()
            if (res?.data) {
                setTimeout(() => {
                    setAUTH_STATUS("SUCCESS")
                }, 1000);
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                setAUTH_STATUS("FAILED")
            }
        }


    }

    useEffect(() => {
        checkAuthentication()

        
    }, [AUTH_STATUS])


    return (
        <Fragment >
            {AUTH_STATUS === "SUCCESS" ?
                <AdminPanelLayout>
                    <Outlet />
                </AdminPanelLayout> : <div className="loading-screen"><img className="loading-image"  src='/assets/img/LOGO-DARK.svg' /></div>}
        </Fragment>

    )
}

export default AdminRouter