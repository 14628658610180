import axios from "axios";


export const getActivityRequest = () => axios.get(`/activity/localized`);

export const getActivityPagableRequest = (pagableData) => axios.get(`/activity/localizedPagable?page=${pagableData.page}&size=${pagableData.size}`);

export const getActivitySliderRequest = () => axios.get(`/activity/slider`);

export const getActivityDiscoverRequest = () => axios.get(`/activity/discover`);

export const getActivitybyId = (id) => axios.get(`/activity/localized/${id}`);

export const getActivityPhotobyId = (id) => axios.get(`/activity/localized/${id}/photo`);

export const searchRequest = ({ categoryId, city }) => axios.get(`/activity/search/${categoryId}/${city}/`);

