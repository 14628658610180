import { Button, Col, Row, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getActivityPhotobyId } from '../api/controllers/activity-controller';

const Card = ({ discoverData, loadMore }) => {
    const { t, i18n } = useTranslation("common");
    let sign = localStorage.getItem('currencySign')
    let navigate = useNavigate();



    useEffect(() => {

    }, [discoverData])

    return (
        <div style={{ width: "100%" }}>
            <Row className='kesfet-middle' gutter={[20, 20]} >
                {discoverData.map((val, i) => (
                    <Col key={i} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <div className='card-root' onClick={() => navigate(`../detail?id=${val.id}`)} style={{ backgroundImage: `url(${val.url})` }} >
                            <div className='card-overlay'>
                                <div className='card-top'>
                                    <img style={{ paddingRight: "10px" }} src='/assets/img/location.svg' />
                                    <Text className='card-top-item1'>
                                        {val.city}
                                    </Text>
                                </div>
                                <div className='card-bottom'>
                                    <div className='card-bottom-top'>
                                        <Text className='card-bottom-top-item1'>
                                            {val.title}
                                        </Text>
                                    </div>
                                    <Row className='card-bottom-bottom'>
                                        <Col style={{ display: "flex", alignItems: "center" }} span={24} >
                                            <img style={{ paddingRight: "5px" }} src='/assets/img/etiket.svg' />
                                            <div className='card-bottom-bottom-right'>
                                                <div className='card-bottom-bottom-right'>
                                                    <Tooltip title={t("yetişkin2")}>
                                                        <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/ADULTS-LIGHT.svg" />} />
                                                    </Tooltip>
                                                    <Text className='card-bottom-bottom-right-text'>{discoverData[i].priceAdult.toFixed(0)} {sign}</Text>
                                                </div>
                                                <div className='card-bottom-bottom-right'>
                                                    <Tooltip title={t("çocuk2")}>
                                                        <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/CHILDS-LIGHT.svg" />} />
                                                    </Tooltip>
                                                    <Text className='card-bottom-bottom-right-text'>{discoverData[i].priceChild.toFixed(0)} {sign}</Text>
                                                </div>
                                                <div className='card-bottom-bottom-right'>
                                                    <Tooltip title={t("bebek2")}>
                                                        <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/BABY-LIGHT.svg" />} />
                                                    </Tooltip>
                                                    <Text className='card-bottom-bottom-right-text'>{discoverData[i].priceInfant.toFixed(0)} {sign}</Text>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))
                }
            </Row>

        </div>
    )
}
export default Card;