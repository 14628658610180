import axios from "axios";


export const addCategoryRequest = (categoryData) => axios.post(`/category`, categoryData);

export const getCategoryRequest = () => axios.get(`/category`);

export const updateCategoryRequest = ({ id, categoryData }) => axios.put(`/category/${id}`, categoryData);

export const getCategorybyId = (id) => axios.get(`/category/${id}`);

export const deleteCategoryByIdRequest = ( id ) => axios.delete(`/category/${id}`)
