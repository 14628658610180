import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Input, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from "../../components/Card";
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';

const DeliveryCancellationandRefund = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("delivery-cancellation-and-refund")


    const [showNav, setShowNav] = useState();

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])


    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <Row gutter={[20, 20]} >
                            <Col span={24}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto" }}>
                                    <div className='search-top'>
                                        <Text className='kesfet-baslik'>{t("teslimatiptalveiade")}</Text>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Text className='detail-text'>{t("linetext1")}</Text>
                                        <Text className='detail-baslik'>{t("line1")}</Text>
                                        <Text className='detail-text'>{t("linetext2")}</Text>
                                        <Text className='detail-text'>{t("linetext3")}</Text>
                                        <Text className='detail-baslik'>{t("line2")}</Text>
                                        <Text className='detail-text'>{t("linetext4")}</Text>
                                        <Text className='detail-baslik'>{t("line3")}</Text>
                                        <Text className='detail-text'>{t("linetext5")}</Text>
                                        <Text className='detail-baslik'>{t("linetext6")}</Text>
                                        <Text className='detail-text'>{t("linetext7")}</Text>
                                        <Text className='detail-baslik'>{t("line4")}</Text>
                                        <Text className='detail-text'>{t("linetext8")}</Text>
                                        <Text className='detail-baslik'>{t("line5")}</Text>
                                        <Text className='detail-text'>{t("linetext9")}</Text>
                                        <Text className='detail-baslik'>{t("line6")}</Text>
                                        <Text className='detail-text'>{t("linetext10")}</Text>
                                        <Text className='detail-text'>{t("linetext11")}</Text>

                                    </div>

                                </div>
                            </Col>

                        </Row>
                    </div>
                    <Info />
                </div>
            </div>
        </StickyContainer>
    )
}

export default DeliveryCancellationandRefund

