import axios from "axios";


export const addActivityRequest = (activityData) => axios.post(`/activity`, activityData);

export const getActivityRequest = () => axios.get(`/activity`);

export const updateActivityRequest = ({ id, activityData }) => axios.put(`/activity/${id}`, activityData);

export const changeIsActive = ({ id, isActive }) => axios.put(`/activity/${id}/hide?isActive=${isActive}`);

export const updateActivityPhotoRequest = (photoEditData) => axios.put(`/activity/photo/sequenceUpdate`, photoEditData);

export const getActivitybyId = (id) => axios.get(`/activity/${id}`);

export const getActivityPhotobyId = (id) => axios.get(`/activity/${id}/photo`);

export const getActivityPhotobyIdSequence = (id, sequence) => axios.get(`/activity/${id}/photo/${sequence}/url`);

export const deleteActivityByIdRequest = (id) => axios.delete(`/activity/${id}`)

export const deletePhotoRequest = ({ id, sequence }) => axios.delete(`/activity/${id}/photo/${sequence}`)


