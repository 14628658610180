import { Button, Collapse, Input, InputNumber, Layout, Menu, notification, Table, Tabs } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './AdminPanel.css';
import { useNavigate } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/Buttons';
import { addCategoryRequest, getCategoryRequest } from '../../api/admincontrollers/category-controller';


const Category = () => {
  const { t, i18n } = useTranslation("common");
  const { Panel } = Collapse;

  let navigate = useNavigate();

  const [data, setData] = useState([])
  const [IsDisabled, setIsDisabled] = useState(false)

  const openNotification = ({ message, type }) => {
    if (type === "info") {
      notification.info({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "error") {
      notification.error({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "success") {
      notification.success({
        message: message,
        placement: 'bottomLeft',
      });
    }
  };

  const [categoryData, setCategoryData] = useState({
    title: {
      tr: null,
      en: null,
      de: null,
      ru: null,
      ar: null,
      pl: null,
      fr: null,
      sq: null,
      es: null,
      localizedText: null
    }
  })

  const columns = [
    {
      title: 'Türkçe',
      dataIndex: 'tr',
    },
    {
      title: 'İngilizce',
      dataIndex: 'en',
    },
    {
      title: 'Almanca',
      dataIndex: 'de',
    },
    {
      title: 'Rusça',
      dataIndex: 'ru',
    },
    {
      title: 'Arapça',
      dataIndex: 'ar',
    },
    {
      title: 'Lehçe',
      dataIndex: 'pl',
    },
    {
      title: 'Fransızca',
      dataIndex: 'fr',
    },
    {
      title: 'Arnavutça',
      dataIndex: 'sq',
    },
    {
      title: 'ispanyolca',
      dataIndex: 'es',
    },


  ];


  const saveCategory = async () => {
    setIsDisabled(true)
    setTimeout(() => {
      setIsDisabled(false)
    }, 1500);

    try {
      let res = await addCategoryRequest(categoryData)
      if (res) {
        openNotification({ message: "Kategori eklendi", type: "success" })
        navigate("/admin/categoryView")
      }

    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }




  return (
    <div className='kategori-root' >
      <div className='kategori-root-top'>
        <Text className='kategori-baslik'>Kategori Ekleme</Text>
        <div style={{ width: "100px" }}>
          <CustomButton loading={IsDisabled} onClick={saveCategory} text={"Kaydet"} colorType={"login-button-blue"} />
        </div>
      </div>
      <div>
        <Collapse defaultActiveKey={['1', '2']} style={{ marginBottom: "10px", marginTop: "10px" }} >
          <Panel header="TÜRKÇE" key="1">
            <Input onChange={(e) => {
              categoryData.title.tr = e.target.value
              setCategoryData({ ...categoryData })
            }} value={categoryData?.title?.tr} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
          <Panel header="İNGİLİZCE" key="2">
            <Input onChange={(e) => {
              categoryData.title.en = e.target.value
              setCategoryData({ ...categoryData })
            }} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
          <Panel header="ALMANCA" key="3">
            <Input onChange={(e) => {
              categoryData.title.de = e.target.value
              setCategoryData({ ...categoryData })
            }} value={categoryData?.title?.de} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
          <Panel header="RUSÇA" key="4">
            <Input onChange={(e) => {
              categoryData.title.ru = e.target.value
              setCategoryData({ ...categoryData })
            }} value={categoryData?.title?.ru} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
          <Panel header="ARAPÇA" key="5">
            <Input onChange={(e) => {
              categoryData.title.ar = e.target.value
              setCategoryData({ ...categoryData })
            }} value={categoryData?.title?.ar} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
          <Panel header="LEHÇE" key="6">
            <Input onChange={(e) => {
              categoryData.title.pl = e.target.value
              setCategoryData({ ...categoryData })
            }} value={categoryData?.title?.pl} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
          <Panel header="FRANSIZCA" key="7">
            <Input onChange={(e) => {
              categoryData.title.fr = e.target.value
              setCategoryData({ ...categoryData })
            }} value={categoryData?.title?.fr} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
          <Panel header="ARNAVUTÇA" key="8">
            <Input onChange={(e) => {
              categoryData.title.sq = e.target.value
              setCategoryData({ ...categoryData })
            }} value={categoryData?.title?.sq} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
          <Panel header="İSPANYOLCA" key="9">
            <Input onChange={(e) => {
              categoryData.title.es = e.target.value
              setCategoryData({ ...categoryData })
            }} value={categoryData?.title?.es} className="admin-input" size="large" placeholder={t("Kategori başlığı")} />
          </Panel>
        </Collapse>
      </div>
    </div>
  )
}


export default Category;
