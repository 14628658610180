import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Select, Form, Input, Tooltip } from 'antd';
import { MenuIcon, SideNav } from 'react-simple-sidenav';
import Text from 'antd/lib/typography/Text';
import { CloseOutlined, ShoppingCartOutlined, WhatsAppOutlined } from '@ant-design/icons/lib/icons';
import CustomButton from './Buttons';
import { useNavigate } from 'react-router';
import i18n from '../i18n';
import moment from 'moment';
import 'moment/locale/tr'
import 'moment/locale/en-nz'
import 'moment/locale/de'
import 'moment/locale/ru'
import 'moment/locale/ar-sa'
import 'moment/locale/sq'
import 'moment/locale/pl'
import 'moment/locale/es'
import 'moment/locale/fr'
import { useTranslation } from 'react-i18next';


const StickyTop = ({ setShowNav, contentLeft, contentRight }) => {
    const { t, i18n } = useTranslation("common");
    const { Option } = Select;
    var url = window.location;
    let navigate = useNavigate();
    let cart = JSON.parse(localStorage.getItem("rezervationInfo") || "[]");

    const [scroll, setScroll] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState();
    const [currentCurrency, setCurrentCurrency] = useState();

    const currencySignSave = () => {
        currentCurrency === "TRY" ? localStorage.setItem('currencySign', "₺") :
            currentCurrency === "USD" ? localStorage.setItem('currencySign', "$") :
                currentCurrency === "EUR" ? localStorage.setItem('currencySign', "€") : <></>
    }

    const currentLanguageSave = () => {
        if (localStorage.getItem('currentLanguage')) {
            setCurrentLanguage(localStorage.getItem('currentLanguage'))
            localStorage.setItem('currentLanguageCode', localStorage.getItem('currentLanguage') === "TURKISH" ? "tr" : localStorage.getItem('currentLanguage') === "ENGLISH" ? "en" : localStorage.getItem('currentLanguage') === "GERMAN" ? "de" : localStorage.getItem('currentLanguage') === "RUSSIAN" ? "ru" : localStorage.getItem('currentLanguage') === "ARABIC" ? "ar-sa" : localStorage.getItem('currentLanguage') === "POLISH" ? "pl" : localStorage.getItem('currentLanguage') === "FRENCH" ? "fr" : localStorage.getItem('currentLanguage') === "ALBANIAN" ? "sq" : localStorage.getItem('currentLanguage') === "SPANISH" ? "es" : "en")
            localStorage.getItem('currentLanguageCode')
            moment.locale(localStorage.getItem('currentLanguageCode'))
        }
        else {
            localStorage.setItem('currentLanguage', "ENGLISH")
            localStorage.getItem('currentLanguage')
            localStorage.setItem("currentLanguageCode", "en")
            localStorage.getItem("currentLanguageCode")
            setCurrentLanguage(localStorage.getItem('currentLanguage'))
            moment.locale(localStorage.getItem('currentLanguageCode'))
        }
    }

    const currentCurrencySave = () => {
        if (localStorage.getItem('currentCurrency')) {
            setCurrentCurrency(localStorage.getItem('currentCurrency'))
        }
        else {
            localStorage.setItem('currentCurrency', "EUR")
            localStorage.getItem('currentCurrency')
            setCurrentCurrency(localStorage.getItem('currentCurrency'))
        }
    }



    const changeLanguageHandler = (lang) => {
        localStorage.setItem('currentLanguage', lang)
        setCurrentLanguage(lang)
        i18n.changeLanguage(lang)

        window.location.reload()
    }

    const changeCurrencyHandler = (currency) => {
        localStorage.setItem('currentCurrency', currency)
        setCurrentLanguage(currency)
        window.location.reload()
    }

    const controlLang = () => {
        i18n.changeLanguage(localStorage.getItem('currentLanguage'))
    }

    useEffect(() => {
        currentLanguageSave()
        currentCurrencySave()

        controlLang()
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);



    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
        return () => {
            window.removeEventListener("scroll", () => {
                setScroll(window.scrollY > 50);
            });
        };
    }, []);



    useEffect(() => {
        currencySignSave()

    }, [currentCurrency])


    return (
        <div className='sticky-top' >

            <div className={scroll ? "sticky-top-transparent" : "sticky-top-no-transparent"}  >

                <div onClick={() => navigate('../')} style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                    <img className='logo-navbar' src='/assets/img/LOGO-LIGHT-FRAME.svg' />
                </div>

                <div className='sticky-select'>
                    <Select
                        value={currentLanguage}
                        onChange={(e) => changeLanguageHandler(e)}
                        dropdownStyle={{ position: "fixed" }}
                    >
                        <Option value="TURKISH">TR</Option>
                        <Option value="ENGLISH">EN</Option>
                        <Option value="GERMAN">DE</Option>
                        <Option value="RUSSIAN">RU</Option>
                        <Option value="ARABIC">AR</Option>
                        <Option value="POLISH">PL</Option>
                        <Option value="FRENCH">FR</Option>
                        <Option value="ALBANIAN">AL</Option>
                        <Option value="SPANISH">ES</Option>
                    </Select>
                    {url.pathname !== "/rez-detail" ? <Select
                        value={currentCurrency}
                        onChange={(e) => changeCurrencyHandler(e)}
                        dropdownStyle={{ position: "fixed" }}
                    >
                        <Option value="TRY">TL</Option>
                        <Option value="USD">USD</Option>
                        <Option value="EUR">EUR</Option>
                    </Select> : <></>}
                    <div className='logout-user'>
                        <Tooltip title={t("sepetim")}>
                            <Button onClick={() => navigate("../shopping-cart")} style={{ backgroundColor: "transparent", border: "none", }} type="primary" shape="circle" icon={cart.length > 0 ? <img className='asd' src='/assets/img/cart-fill.svg' /> : <img className='asd' src='/assets/img/cart.svg' />} />
                        </Tooltip>

                    </div>
                    <MenuIcon className={contentRight} onClick={() => setShowNav(true)} />

                </div>

            </div>
            <a
                href="https://api.whatsapp.com/send?phone=905421001048"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <WhatsAppOutlined />
            </a>
        </div>
    )
}

export default StickyTop;