import { Button, Input, InputNumber, Layout, Menu, Table, Tabs, Collapse, Tooltip, Modal, Row, Col, Select, Alert, notification, Switch } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuOutlined,
  EditOutlined,
  DeleteOutlined,
  FileImageOutlined,
  CheckOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './AdminPanel.css';
import { useNavigate } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/Buttons';
import { getActivitybyId, getActivityPhotobyId, getActivityRequest, updateActivityPhotoRequest, updateActivityRequest, deleteActivityByIdRequest, deletePhotoRequest, changeIsActive } from '../../api/admincontrollers/activity-controller';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RUG from 'react-upload-gallery'
import 'react-upload-gallery/dist/style.css'
import { BASE_URL } from '../../api/ApiProvider';
import { getCategorybyId, getCategoryRequest } from '../../api/admincontrollers/category-controller';


const ActivityView = () => {
  const { t, i18n } = useTranslation("common");
  const { Option } = Select;
  const { Panel } = Collapse;
  const token = localStorage.getItem("token")

  const [data, setData] = useState([])
  const [photos, setPhotos] = useState([])
  const [photoEditData, setPhotoEditData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [activityData, setActivityData] = useState([])
  const [deletePhotoId, setDeletePhotoId] = useState([])

  const [isVisible, setIsVisible] = useState(false)
  const [photoEditIsVisible, setPhotoEditIsVisible] = useState(false)
  const [photoIsVisible, setPhotoIsVisible] = useState(false)
  const [confirmDeletePhotoModal, setConfirmDeletePhotoModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const [deleteActivityId, setDeleteActivityId] = useState()
  const [photoId, setPhotoId] = useState()
  const [sequence, setSequence] = useState(1)
  const [counter, setCounter] = useState(1)
  const [counter2, setCounter2] = useState(-1)


  const openNotification = ({ message, type }) => {
    if (type === "info") {
      notification.info({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "error") {
      notification.error({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "success") {
      notification.success({
        message: message,
        placement: 'bottomLeft',
      });
    }
  };

  const columns = [
    {
      title: 'Türkçe',
      dataIndex: 'tr',
      render: (tr) =>
        tr ? tr :
          <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            -
          </div>
    },
    {
      title: 'İngilizce',
      dataIndex: 'en',
      render: (en) =>
        en ? en :
          <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            -
          </div>
    },
    {
      title: 'Almanca',
      dataIndex: 'de',
      render: (de) =>
        de ? de :
          <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
            -
          </div>
    },
    {
      title: 'Rus',
      dataIndex: 'ru',
      className: "admin-language",
      render: (ru) =>
        ru ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'Arp',
      dataIndex: 'ar',
      className: "admin-language",
      render: (ar) =>
        ar ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red", }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'Leh',
      dataIndex: 'pl',
      className: "admin-language",
      render: (pl) =>
        pl ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'Frs',
      dataIndex: 'fr',
      className: "admin-language",
      render: (fr) =>
        fr ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'Arn',
      dataIndex: 'sq',
      className: "admin-language",
      render: (sq) =>
        sq ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'İsp',
      dataIndex: 'es',
      className: "admin-language",
      render: (es) =>
        es ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: <MenuOutlined />,
      key: 'operation',
      fixed: 'right',
      width: 100,
      dataIndex: "id",
      render: (i) =>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
          <Tooltip title="Aktiviteyi Düzenle">
            <Button onClick={() => {
              openEditModal(i)
            }} style={{ backgroundColor: "rgba(44, 62, 80, 0.1)", border: "none", color: "rgba(44, 62, 80, 1)" }} type="primary" shape="circle" icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip title="Fotoğraf Yükle">
            <Button onClick={() => {
              openPhotoModalUpload(i)
            }} style={{ backgroundColor: "rgba(0, 148, 50,0.1)", border: "none", color: "rgba(0, 148, 50,1)" }} type="primary" shape="circle" icon={<FileImageOutlined />} />
          </Tooltip>
          <Tooltip title="Fotoğraf Düzenle">
            <Button onClick={() => {
              openPhotoModalEdit(i)
            }} style={{ backgroundColor: "rgba(241, 196, 15,0.1)", border: "none", color: "rgba(241, 196, 15,1.0)" }} type="primary" shape="circle" icon={<FileImageOutlined />} />
          </Tooltip>
          <Tooltip Tooltip title="Aktiviteyi Sil" >
            <Button onClick={() => { setConfirmDeleteModal(true); setDeleteActivityId(i) }} style={{ backgroundColor: "rgba(126, 3, 3, 0.1)", border: "none", color: "rgba(126, 3, 3, 1)" }} type="primary" shape="circle" icon={<DeleteOutlined />} />
          </Tooltip >
        </div>
    },
  ];

  const openEditModal = async (id) => {
    try {
      let res = await getActivitybyId(id)
      if (res) {
        setActivityData(res.data)

        setIsVisible(true)
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const openPhotoModalUpload = async (id) => {
    setPhotoId(id)
    try {
      let res = await getActivityPhotobyId(id)
      if (res) {
        setSequence(res.data.length + 1)
        setPhotoIsVisible(true)
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const openPhotoModalEdit = async (id) => {
    setPhotoId(id)
    try {
      let res = await getActivityPhotobyId(id);
      if (res) {
        setPhotos(res?.data);
        setSequence(res?.data?.length + 1);
        setPhotoEditIsVisible(true);
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }


  const fetchCategory = async () => {

    try {
      let res = await getCategoryRequest()
      if (res) {
        setCategoryData(
          res?.data?.map((val, i) => (
            {
              id: val.id,
              key: i,
              tr: val?.title?.tr,
            }
          ))
        )
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const fetchActivity = async () => {

    try {
      let res = await getActivityRequest()
      if (res) {
        setData(
          res?.data.map((val, i) => (
            {
              id: val.id,
              key: i,
              tr: val?.title?.tr,
              en: val?.title?.en,
              de: val?.title?.de,
              ru: val?.title?.ru,
              ar: val?.title?.ar,
              pl: val?.title?.pl,
              fr: val?.title?.fr,
              sq: val?.title?.sq,
              es: val?.title?.es,
            }
          ))
        )
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const updateActivity = async () => {

    activityData.categoryId = activityData.category.id
    setActivityData({ ...activityData })

    try {
      let res = await updateActivityRequest({
        id: activityData.id,
        activityData: activityData
      })
      if (res)
        openNotification({ message: 'Aktivite Güncellendi', type: "success" })
      fetchActivity()
      fetchCategory()
      setTimeout(() => {
        setIsVisible(false)
      }, 1000);
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const newPhotoList = (e) => {

    let obje = {
      sequence: counter,
      id: e.id,
    }
    if (photoEditData.find(v => obje.id === v.id)) {

    }
    else {
      photoEditData.push(obje)
      setPhotoEditData([...photoEditData])
      setCounter(counter + 1)
      setCounter2(counter2 + 1)
      if (photoEditData.length > 0) {
        setDisabled(true)
      }
    }

  }

  const updateActivityPhoto = async () => {
    setIsLoading(true)


    if (photos.length < 1) {
      openNotification({ message: 'Sıralamayı kaydetmek için fotoğraf bulunması gerekir', type: "warning" })
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
    }
    else {
      if (photoEditData.length < photos.length) {
        openNotification({ message: 'Sıralamayı kaydetmek için tüm fotoğrafların seçili olması gerekiyor', type: "warning" })
        setTimeout(() => {
          setIsLoading(false)
        }, 1000);
      }
      else if (photoEditData.length === photos.length) {
        try {
          let res = await updateActivityPhotoRequest(photoEditData)
          if (res) {
            openNotification({ message: 'Fotoğraflar başarılı bir şekilde sıralandı ', type: "success" })
            setTimeout(() => {
              window.location.reload()
            }, 1000);
            setTimeout(() => {
              setIsLoading(false)
            }, 1000);
          }
        } catch (error) {
          error?.response?.data.map((val, i) =>
            openNotification({ message: val.message, type: "error" })
          )
        }
      }
    }
  }

  const photoDelete = (val) => {
    setConfirmDeletePhotoModal(true)
    setDeletePhotoId(val)
  }

  const photoDeleteModal = async () => {

    try {
      let res = await deletePhotoRequest({
        id: deletePhotoId.id,
        sequence: deletePhotoId.sequence
      })
      if (res) {
        openNotification({ message: 'silme işlemi başarılı', type: "success" })
        setConfirmDeletePhotoModal(false)
        openPhotoModalEdit(photoId)
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const DeleteActivity = async () => {
    try {
      let res = await deleteActivityByIdRequest(deleteActivityId)
      if (res) {
        openNotification({ message: 'Aktivite silindi', type: "success" })
        setConfirmDeleteModal(false)
        fetchActivity()
        openPhotoModalUpload()
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const sequenceCounter = () => {
    setSequence(sequence + 1)
  }

  const onChangeSwitch = async (checked) => {
    try {
      let res = await changeIsActive({ id: activityData.id, isActive: !activityData.isActive })
      if (res) {
        openNotification({ message: 'Aktivite aktif-pasif durumu değiştirildi', type: "success" })
        let rem = await getActivitybyId(activityData.id)
        if (rem) {
          setActivityData(rem.data)

          setIsVisible(true)
        }
      }
    } catch (error) {

    }
  };


  useEffect(() => {
    fetchActivity()
    fetchCategory()
  }, [])

  return (
    <div className='kategori-root' >
      <div className='kategori-root-top'>
        <Text className='kategori-baslik'>Aktiviteler</Text>
      </div>
      <div className="users">
        <Table bordered className='table-root' columns={columns} dataSource={data} />
      </div>
      <div>
        <Modal
          title={<div>{activityData?.title?.en} - Aktivitesinin Bilgilerini Düzenle</div>}
          visible={isVisible}
          onOk={() => updateActivity()}
          onCancel={() => {
            setIsVisible(false)

            setActivityData({})
          }}
          okText='Güncelle'
          cancelText='İptal'
          bodyStyle={{ maxHeight: "70vh" }}
        >
          <div className='activity-content-root-mutual'>
            <Text className='kategori-dil'>Bebek Fiyatı</Text>
            <InputNumber onChange={(e) => {

              activityData.priceEuroInfant = e
              setActivityData({ ...activityData })
            }} value={activityData?.priceEuroInfant} style={{ paddingBottom: "10px" }} addonAfter="€" defaultValue={0} />
            <Text className='kategori-dil'>Çocuk Fiyatı</Text>
            <InputNumber onChange={(e) => {

              activityData.priceEuroChild = e
              setActivityData({ ...activityData })
            }} value={activityData?.priceEuroChild} style={{ paddingBottom: "10px" }} addonAfter="€" defaultValue={0} />
            <Text className='kategori-dil'>Yetişkin Fiyatı</Text>
            <InputNumber onChange={(e) => {

              activityData.priceEuroAdult = e
              setActivityData({ ...activityData })
            }} value={activityData?.priceEuroAdult} style={{ paddingBottom: "10px" }} addonAfter="€" defaultValue={0} />
            <Text className='kategori-dil'>Akitivitenin açılacağı ili yazınız</Text>
            <Input onChange={(e) => {
              activityData.city = e.target.value.toUpperCase()
              setActivityData({ ...activityData })
            }} value={activityData?.city} className="admin-input-city" size="large" placeholder="Aktivite şehri" />
            <Text className='kategori-dil'>Aktivitenin içinde olacağı kategoriyi seçin</Text>
            <Select
              showSearch
              style={{ width: '100%', border: "1px solid lightgrey", marginBottom: "10px" }}
              placeholder="Kategoriler"
              optionFilterProp="children"
              onSelect={(e) => {
                activityData.category.title.tr = categoryData.find((i) => i.id === e).tr
                activityData.categoryId = e
                activityData.category.id = e
                setActivityData({ ...activityData })

              }}
              value={activityData?.category?.title?.tr}
            >
              {
                categoryData.map((val, i) => (
                  <Option key={i} value={val.id}>{val.tr}</Option>
                )
                )
              }
            </Select>
            <Text className='kategori-dil'>Aktivite görünürlüğü aktif veya pasif seçimini yapınız</Text>
            <Switch style={{ maxWidth: "50px", paddingBottom: "21.5px", marginBottom: "10px" }} checked={activityData.isActive} onChange={onChangeSwitch} />
          </div>
          <div className='kategori-root-bottom'>
            <Collapse defaultActiveKey={['1']} style={{ width: "100%", marginBottom: "10px", marginTop: "10px", overflow: "auto", minWidth: "700px" }} >
              <Panel header="TÜRKÇE" key="1">
                <Input onChange={(e) => {

                  activityData.title.tr = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.tr} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.tr}

                    onChange={(event, editor) => {
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      activityData.overview.tr = data
                      setActivityData({ ...activityData })
                    }}

                  />
                </div>
              </Panel>
              <Panel header="İNGİLİZCE" key="2">
                <Input onChange={(e) => {

                  activityData.title.en = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.en} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.en}
                    onChange={(event, editor) => {

                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      activityData.overview.en = data
                      setActivityData({ ...activityData })
                    }}
                  />
                </div>
              </Panel>
              <Panel header="ALMANCA" key="3">
                <Input onChange={(e) => {

                  activityData.title.de = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.de} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.de}
                    /*  onChange={(event, editor) => {}} */
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      /* console.log({ event, editor, data }); */
                      activityData.overview.de = data
                      setActivityData({ ...activityData })
                    }}
                  />
                </div>
              </Panel>
              <Panel header="RUSÇA" key="4">
                <Input onChange={(e) => {

                  activityData.title.ru = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.ru} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.ru}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      activityData.overview.ru = data
                      setActivityData({ ...activityData })
                    }}
                  />
                </div>
              </Panel>
              <Panel header="ARAPÇA" key="5">
                <Input onChange={(e) => {

                  activityData.title.ar = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.ar} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.ar}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      activityData.overview.ar = data
                      setActivityData({ ...activityData })
                    }}
                  />
                </div>
              </Panel>
              <Panel header="LEHÇE" key="6">
                <Input onChange={(e) => {

                  activityData.title.pl = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.pl} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.pl}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      activityData.overview.pl = data
                      setActivityData({ ...activityData })
                    }}
                  />
                </div>
              </Panel>
              <Panel header="FRANSIZCA" key="7">
                <Input onChange={(e) => {

                  activityData.title.fr = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.fr} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.fr}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      activityData.overview.fr = data
                      setActivityData({ ...activityData })
                    }}
                  />
                </div>
              </Panel>
              <Panel header="ARNAVUTÇA" key="8">
                <Input onChange={(e) => {

                  activityData.title.sq = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.sq} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.sq}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      activityData.overview.sq = data
                      setActivityData({ ...activityData })
                    }}
                  />
                </div>
              </Panel>
              <Panel header="İSPANYOLCA" key="9">
                <Input onChange={(e) => {

                  activityData.title.es = e.target.value
                  setActivityData({ ...activityData })
                }} value={activityData?.title?.es} className="admin-input" size="large" placeholder="Aktivite başlığı" />
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={activityData?.overview?.es}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      activityData.overview.es = data
                      setActivityData({ ...activityData })
                    }}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          title={<div>Aktiviteye Fotoğraf Yükle</div>}
          visible={photoIsVisible}
          onCancel={() => { setPhotoIsVisible(false) }}
          bodyStyle={{ maxHeight: "80vh" }}
          footer={null}
        >
          <div className='photo-modal-root'>
            <RUG
              /* rules={{ limit: 10, }} */
              headers={{ Authorization: `Bearer ${token}` }}
              inOrder={true}
              autoUpload={false}
              action={`${BASE_URL}/api/v1/activity/${photoId}/photo/${sequence}`}// upload route
              source={response => response.source} // response image source
              sorting={false}
              accept={['jpg', 'jpeg', 'png', 'gif']}
              onSuccess={() => sequenceCounter()}
            />
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          title={<div>Aktivitenin Fotoğraflarını Düzenleme</div>}
          visible={photoEditIsVisible}
          onOk={() => updateActivityPhoto()}
          onCancel={() => {
            setCounter(0)
            setPhotoEditIsVisible(false)
            window.location.reload()
          }}
          okText='Sıralamayı kaydet'
          cancelText='İptal'
          bodyStyle={{ display: "flex", flexDirection: "column", maxHeight: "80vh", width: "100%" }}
          footer={[
            <Button key="back" onClick={() => {
              setCounter(0)
              setPhotoEditIsVisible(false)
              window.location.reload()
            }}>
              İptal
            </Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={() => updateActivityPhoto()}>
              Sıralamayı kaydet
            </Button>,
          ]}
        >

          {photos.length > 0 && photoEditData.length < 1 ? <div className='photo-edit-model-info'>
            <Alert message="Görüntü sıralamasını değiştirmek için fotoğraflara yeni sıralamasıyla tıklayın." type="info" showIcon />
          </div> : <></>}
          <div className='photo-edit-modal-root'>

            {photos.length > 0 ?
              photos.map((val, i) =>
                <div key={i} style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
                  <div className={photoEditData[counter2]?.id === val.id ? 'photo-editted' : 'photo-editted-null'} onClick={() => newPhotoList(val)} style={{ display: "flex", flexDirection: "row", width: "150px", height: "150px", display: "flex", alignItems: "center", backgroundColor: "#3d4852", borderRadius: "5px", cursor: "pointer", padding: "10px" }}>
                    <img style={{ width: "100%", height: "auto" }} src={val.url} />
                  </div>
                  <div className='photo-edit-button'>
                    <Tooltip Tooltip title="Fotoğrafı Kaldır" >
                      <Button disabled={disabled} onClick={() => photoDelete(val)}
                        style={{ backgroundColor: "rgba(126, 3, 3)", border: "none", margin: "2px", opacity: "0.5" }} size="small" type="primary" shape="circle" icon={<CloseCircleOutlined />} />
                    </Tooltip >
                  </div>
                </div>
              )
              :
              <div>
                Aktiviteye yüklenmiş fotoğraf bulunmamaktadır
              </div>
            }

          </div>
        </Modal>
      </div >
      <Modal
        title="Aktiviteyi sil"
        visible={confirmDeleteModal}
        onOk={DeleteActivity}
        okText={'Sil'}
        cancelText={'İptal'}
        onCancel={() => setConfirmDeleteModal(false)}
        width={300}
        className='reset-modal'
        bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
      >
        <div>Aktiviteyi silmek istediğinize emin misiniz?</div>
      </Modal>
      <Modal
        title="Fotoğrafı sil"
        visible={confirmDeletePhotoModal}
        onOk={photoDeleteModal}
        okText={'Sil'}
        cancelText={'İptal'}
        onCancel={() => setConfirmDeletePhotoModal(false)}
        width={300}
        className='reset-modal'
        bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
      >
        <div>Fotoğrafı silmek istediğinize emin misiniz?</div>
      </Modal>
    </div >
  )
}

export default ActivityView;
