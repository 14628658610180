import { Button, Input, InputNumber, Layout, Menu, Table, Tabs, Collapse, Tooltip, notification } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  MenuOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './AdminPanel.css';
import { useNavigate } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/Buttons';
import { deleteCategoryByIdRequest, getCategorybyId, getCategoryRequest, updateCategoryRequest } from '../../api/admincontrollers/category-controller';
import Modal from 'antd/lib/modal/Modal';


const CategoryView = () => {
  const { t, i18n } = useTranslation("common");
  const { Panel } = Collapse;

  const [data, setData] = useState([])
  const [isVisible, setIsVisible] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [deleteCategoryId, setDeleteCategoryId] = useState()

  const [categoryData, setCategoryData] = useState([])

  const openNotification = ({ message, type }) => {
    if (type === "info") {
      notification.info({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "warning") {
      notification.warning({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "error") {
      notification.error({
        message: message,
        placement: 'bottomLeft',
      });
    } else if (type === "success") {
      notification.success({
        message: message,
        placement: 'bottomLeft',
      });
    }
  };

  const columns = [
    {
      title: 'Türkçe',
      dataIndex: 'tr',
    },
    {
      title: 'İngilizce',
      dataIndex: 'en',
    },
    {
      title: 'Almanca',
      dataIndex: 'de',
    },
    {
      title: 'Rus',
      dataIndex: 'ru',
      className: "admin-language",
      render: (ru) =>
        ru ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'Arp',
      dataIndex: 'ar',
      className: "admin-language",
      render: (ar) =>
        ar ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'Leh',
      dataIndex: 'pl',
      className: "admin-language",
      render: (pl) =>
        pl ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'Frs',
      dataIndex: 'fr',
      className: "admin-language",
      render: (fr) =>
        fr ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'Arn',
      dataIndex: 'sq',
      className: "admin-language",
      render: (sq) =>
        sq ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: 'İsp',
      dataIndex: 'es',
      className: "admin-language",
      render: (es) =>
        es ? <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <img style={{ color: "red" }} src='/assets/img/check-circle.svg' />
        </div> : <div>-</div>
    },
    {
      title: <MenuOutlined />,
      key: 'operation',
      fixed: 'right',
      width: 100,
      dataIndex: "id",
      render: (i) =>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
          <Tooltip title="Düzenle">
            <Button onClick={() => {
              openEditModal(i)
            }}
              style={{ backgroundColor: "rgba(44, 62, 80, 0.1)", border: "none", color: "rgba(44, 62, 80, 1)" }} type="primary" shape="circle" icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip Tooltip title="Sil" >
            <Button onClick={() => { setConfirmDeleteModal(true); setDeleteCategoryId(i) }/* DeleteCategory(i) */} style={{ backgroundColor: "rgba(126, 3, 3, 0.1)", border: "none", color: "rgba(126, 3, 3, 1)" }} type="primary" shape="circle" icon={<DeleteOutlined />} />
          </Tooltip >
        </div>
    },

  ];

  const openEditModal = async (id) => {
    try {
      let res = await getCategorybyId(id)
      if (res) {
        setCategoryData(res.data)
        setIsVisible(true)
      }
    } catch (error) {

    }
  }


  const fetchCategory = async () => {
    try {
      let res = await getCategoryRequest()
      if (res) {
        setData(
          res?.data.map((val, i) => (
            {
              id: val.id,
              key: i,
              tr: val?.title?.tr,
              en: val?.title?.en,
              de: val?.title?.de,
              ru: val?.title?.ru,
              ar: val?.title?.ar,
              pl: val?.title?.pl,
              fr: val?.title?.fr,
              sq: val?.title?.sq,
              es: val?.title?.es,
            }
          ))
        )
      }

      /*  if (res?.status === 200 && res?.data?.role === "ROLE_ADMIN") {
           setTimeout(() => {
               navigate("/admin/home-page")
           }, 500);
       } else if (res?.status === 200 && res?.data?.role === "ROLE_USER") {
           setTimeout(() => {
               navigate("/")
           }, 500);
       } */
    } catch (error) {
    }
  }

  const updateCategory = async () => {
    try {
      let res = await updateCategoryRequest({
        id: categoryData.id,
        categoryData: categoryData
      })
      if (res)
        fetchCategory()
      openNotification({ message: 'Kategori Güncellendi', type: "success" })
      setIsVisible(false)
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  const DeleteCategory = async () => {
    try {
      let res = await deleteCategoryByIdRequest(deleteCategoryId)
      if (res) {
        openNotification({ message: 'Kategori silindi', type: "success" })
        setConfirmDeleteModal(false)
        fetchCategory()
      }
    } catch (error) {
      error?.response?.data.map((val, i) =>
        openNotification({ message: val.message, type: "error" })
      )
    }
  }

  useEffect(() => {

    fetchCategory()


  }, [])


  return (
    <div className='kategori-root' >
      <div className='kategori-root-top'>
        <Text className='kategori-baslik'>Kategoriler</Text>
      </div>
      <div className="users">
        <Table bordered className='table-root' columns={columns} dataSource={data} />
      </div>
      <div>
        <Modal
          title={<div>{categoryData?.title?.en} - Kategori Bilgilerini Düzenle</div>}
          visible={isVisible}
          onOk={() => updateCategory()}
          onCancel={() => setIsVisible(false)}
          okText='Güncelle'
          cancelText='İptal'
          bodyStyle={{ maxHeight: "70vh" }}
        >
          <div className='modal-root'>
            <Collapse defaultActiveKey={['1', '2']} style={{ width: "100%", marginBottom: "10px", marginTop: "10px", overflow: "auto" }} >
              <Panel header="TÜRKÇE" key="1">
                <Input onChange={(e) => {
                  categoryData.title.tr = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.tr} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
              <Panel header="İNGİLİZCE" key="2">
                <Input onChange={(e) => {
                  categoryData.title.en = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.en} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
              <Panel header="ALMANCA" key="3">
                <Input onChange={(e) => {
                  categoryData.title.de = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.de} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
              <Panel header="RUSÇA" key="4">
                <Input onChange={(e) => {
                  categoryData.title.ru = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.ru} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
              <Panel header="ARAPÇA" key="5">
                <Input onChange={(e) => {
                  categoryData.title.ar = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.ar} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
              <Panel header="LEHÇE" key="6">
                <Input onChange={(e) => {
                  categoryData.title.pl = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.pl} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
              <Panel header="FRANSIZCA" key="7">
                <Input onChange={(e) => {
                  categoryData.title.fr = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.fr} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
              <Panel header="ARNAVUTÇA" key="8">
                <Input onChange={(e) => {
                  categoryData.title.sq = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.sq} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
              <Panel header="İSPANYOLCA" key="9">
                <Input onChange={(e) => {
                  categoryData.title.es = e.target.value
                  setCategoryData({ ...categoryData })
                }} value={categoryData?.title?.es} className="admin-input" size="large" placeholder={"Kategori başlığı"} />
              </Panel>
            </Collapse>
          </div>
        </Modal>
      </div>
      <Modal
        title="Kategoriyi sil"
        visible={confirmDeleteModal}
        onOk={DeleteCategory}
        okText={'Sil'}
        cancelText={'İptal'}
        onCancel={() => setConfirmDeleteModal(false)}
        width={300}
        className='reset-modal'
        bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
      >
        <div>Kategoriyi silmek istediğinize emin misiniz?</div>
      </Modal>
    </div >
  )
}


export default CategoryView;
