import axios from "axios";


export const LoginRequest = (loginData) => axios.post(`/account/login`, loginData);

export const RegisterRequest = (registerData) => axios.post(`/account/register`, registerData);

export const verificationRequest = ({ code, email }) => axios.post(`/account/confirmCode`, { code, email });

export const ResetPasswordRequest = ({ email }) => axios.post(`/account/forgotPassword/sendMail`, { email });

export const ResetPasswordRequest2 = ({ verificationCode, newPassword, newEmail }) => axios.post(`/account/resetPassword`, { verificationCode, newPassword, newEmail });

export const getMeRequest = () => axios.get(`/account`);

export const confirmTokenRequest = (token) => axios.get(`/account/confirmToken?token=${token}`);






