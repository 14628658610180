import { Button } from "antd";
import React from "react";
// login-button-white - login-button-blue - login-button-transparent-white  login-button-transparent-blue   kullanım şekli 
// <CustomButton text={"GİRİŞ YAP"} colorType={"login-button-blue"} />
// <CustomButton text={"GİRİŞ YAP"} colorType={"login-button-transparent-white"} />
const CustomButton = ({ text, colorType, onClick, disabled, loading,type }) => {

    return (
        <Button htmlType={type} loading={loading} disabled={disabled} onClick={onClick} className={colorType}>{text}</Button>
    )
}
export default CustomButton;