import { Col, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Info = () => {
    const { t, i18n } = useTranslation("common");

    let navigate = useNavigate();

    return (
        <div className='info-root'>
            <Row className='info-root-top'>
                <Col span={3}>
                    <img className='info-img' src='/assets/img/iyzico.svg' />
                </Col>
                <Col span={3}>
                    <img className='info-img' src='/assets/img/tursab.svg' />
                </Col>
                <Col span={3}>
                    <img className='info-img' src='/assets/img/Jollytur.svg' />
                </Col>
                <Col span={3}>
                    <img className='info-img' src='/assets/img/tatilbudur.svg' />
                </Col>
                <Col span={3}>
                    <img className='info-img' src='/assets/img/gezinomi.svg' />
                </Col>
                <Col span={3}>
                    <img className='info-img' src='/assets/img/prontotur.svg' />
                </Col>
                <Col span={3}>
                    <img className='info-img' src='/assets/img/visa.svg' />
                </Col>
                <Col span={3}>
                    <img className='info-img' src='/assets/img/mastercard.svg' />
                </Col>

            </Row>
            <Row gutter={[20, 20]} className='info-root-middle'>
                <Col xs={24} sm={12} md={12} lg={7}>
                    <div className='info-middle1'>
                        <img style={{ width: "150px" }} src='/assets/img/LOGO-LIGHT-FRAME.svg' />
                        <Text style={{ cursor: "default" }} className='info-middle1-middle'>{t("birsonrakiseyahatiniziplanlayın")}</Text>
                        <div className='info-middle1-item2'>
                            <img onClick={() => window.location.href = "https://www.facebook.com/Visitmarmaris-101449835387827/"} className='info-img2' src='/assets/img/face.svg' />
                            <img onClick={() => window.location.href = "https://www.instagram.com/bbtour_marmaris/"} className='info-img2' src='/assets/img/insta.svg' />
                            <img onClick={() => window.location.href = "https://twitter.com/visitmarmaris1/"} className='info-img2' src='/assets/img/twitter.svg' />
                            {/* <img  className='info-img2' src='/assets/img/in.svg' /> */}
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={5}>
                    <div className='info-middle2'>
                        <Text className='info-middle1-middle-baslik'>{t("hakkımızda")}</Text>
                        <Text onClick={() => navigate("../about-us")} className='info-middle1-middle-geçici'>{t("bizkimiz")}</Text>
                        {/*  <Text className='info-middle1-middle'>{t("sertifikalarımız")}</Text>
                        <Text className='info-middle1-middle'>{t("anlaşmalarımız")}</Text> */}
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                    <div className='info-middle1'>
                        <Text className='info-middle1-middle-baslik'>{t("sözleşmevegüvenceler")}</Text>
                        <Text onClick={() => navigate("../distance-sales-contract")} className='info-middle1-middle'>{t("mesafelisatışsözleşmesi")}</Text>
                        <Text onClick={() => navigate("../terms-of-service")} className='info-middle1-middle'>{t("kullanımkoşulları")}</Text>
                        <Text onClick={() => navigate("../delivery-cancellation-and-refund")} className='info-middle1-middle'>{t("teslimatiptalveiade")}</Text>
                        <Text onClick={() => navigate("../privacy-and-security")} className='info-middle1-middle'>{t("gizliliksözleşmesi")}</Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                    <div className='info-middle1'>
                        <Text className='info-middle1-middle-baslik'>{t("iletişim")}</Text>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ paddingRight: "5px" }} src='/assets/img/phone.svg' />
                            <Text style={{ cursor: "default" }} className='info-middle1-middle'>+90 542 100 10 48</Text>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ paddingRight: "5px" }} src='/assets/img/location2.svg' />
                            <Text style={{ cursor: "default" }} className='info-middle1-middle'>Cumhuriyet Bul. No.2, 48700 Marmaris/Mugla / TURKEY.</Text>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img style={{ paddingRight: "5px" }} src='/assets/img/mailWhite.svg' />
                            <Text onClick={() => navigate("../contact-form")} className='info-middle1-middle'>{t("iletişimformu")}</Text>
                        </div>

                    </div>
                </Col>
            </Row>
            <div className='info-bottom' >
                <Text >{t("tümhakkısaklıdır")}</Text>
            </div>

        </div>
    )
}
export default Info;