import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Alert, Button, Col, Input, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from "../../components/Card";
import { StickyContainer } from "react-sticky";
import StickyTop from "../../components/StickyTop";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import { searchRequest } from "../../api/controllers/activity-controller";

const Search = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("search")

    var url = window.location;
    let id = new URLSearchParams(url.search).get('id');
    let city = new URLSearchParams(url.search).get('city');

    const [showNav, setShowNav] = useState();
    const [searchData, setSearchData] = useState([])
    const [isVisible, setIsVisible] = useState(false)

    let navigate = useNavigate();

    const search = async () => {

        try {
            if (city === "") {
                city = "="
            }
            let res = await searchRequest({ categoryId: id, city: city })
            if (res) {
                setSearchData(res.data)
                isEmpty()
            }

        } catch (error) {

        }
    }

    const isEmpty = () => {

        if (searchData.length < 1) {
            setIsVisible(true)

        }
    }

    useEffect(() => {
        setShowNav(false)
        search()

    }, [id])


    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />
            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <div className='search-top'>
                            <Text className='kesfet-baslik'>{t("arama")}</Text>
                            <Text className='search-text'> {t("sonuçları")}</Text>
                        </div>
                        {isVisible === true && searchData.length === 0 ?
                            <Alert
                                message={t("sonuçbulunamadı")}
                                description={t("aradığınızkriterlereuygunsonuçbulamadıkbirdahadeneyiniz")}
                                type="warning"
                                style={{ marginBottom: "0px" }}
                            />
                            : <></>
                        }
                        <div className="cart-root">
                            <Card discoverData={searchData} />
                        </div>

                        <p className="search-bottom-text1">{t("aradığınızıbulamadınızmı")}  <Button onClick={() => navigate("../")} className="search-bottom-button">{t("yeniaramayapın")}</Button> </p>
                    </div>
                    <Info />
                </div>
            </div>
        </StickyContainer>
    )
}

export default Search