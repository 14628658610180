import { LockOutlined, MailOutlined, UserOutlined, InfoCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, CloseOutlined, ShoppingCartOutlined } from "@ant-design/icons/lib/icons";
import { Button, Col, Input, Row, Tabs, Checkbox, Tooltip, Modal, notification } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from "../../components/Card";
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import { getMeRequest } from "../../api/controllers/account-controller";
import { getActivitybyId } from "../../api/controllers/activity-controller";
import { paymentNonMemberRequest, paymentRequest, postCartRequest, rezervationNonMemberRequest, rezervationRequest } from "../../api/controllers/payment-controller";

const ShoppingCart = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("shopping-cart")

    const { TabPane } = Tabs;
    const { confirm } = Modal;
    let navigate = useNavigate();
    let sign = localStorage.getItem('currencySign')
    const firstData = JSON.parse(localStorage.getItem("firstData"))
    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };

    const [role, setRole] = useState("")
    const [showNav, setShowNav] = useState();
    const [paymentModalVisible, setPaymentModalVisible] = useState(false)
    const [rezervationModalVisible, setRezervationModalVisible] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [indexesToBeRemoved, setIndexesToBeRemoved] = useState([])
    const [shownData, setShownData] = useState([])
    const [cartData, setCartData] = useState([])
    const [cartItems, setCartItems] = useState([])
    const [nonMember, setNonMember] = useState({})
    const [nonMemberRez, setNonMemberRez] = useState({})
    const [myAccountData, setMyAccountData] = useState([])
    const [isDisabled3, setIsDisabled3] = useState(true)
    const [isDisabled, setIsDisabled] = useState(false)
    const [isDisabled2, setIsDisabled2] = useState(false)
    const [currency, setcurrency] = useState(localStorage.getItem('currentCurrency') ? localStorage.getItem('currentCurrency') : "TRY")
    const [totalPricePayment, setTotalPricePayment] = useState(0)

    console.log('currency', currency)

    let cart = JSON.parse(localStorage.getItem("rezervationInfo") || "[]");

    for (let i = 0; i < cart.length; i++) {
        cart[i].id = i + 1
        localStorage.setItem("rezervationInfo", JSON.stringify(cart))
    }

    function onChange(i, e) {

        if (e.target.checked === true) {
            cartItems.push({
                activityId: cart[i].activityId,
                activityDate: cart[i].activityDate,
                travelerList: cart[i].travelerList,
                id: i + 1,
            })
            setCartItems([...cartItems])

            setTotalPricePayment(totalPricePayment + shownData.cartItems[i].sideData[0].totalPrice)
        }
        else if (e.target.checked === false) {
            setCartItems(cartItems.filter((v) => v.id !== e.target.value))
            setTotalPricePayment(totalPricePayment - shownData.cartItems[i].sideData[0].totalPrice)
        }
        setCartData({
            cartItems: cartItems,
            currency: currency
        })

    }

    function callback(key) {
    }

    const getMyAccount = async () => {
        try {
            let res = await getMeRequest()
            setMyAccountData(res.data)

        } catch (error) {

        }
    }

    const payment = async () => {
        setIsDisabled(true)
        setTimeout(() => {
            setIsDisabled(false)
        }, 1500);
        if (role) {
            if (cartItems.length > 0) {
                try {
                    let res = await paymentRequest(cartData)
                    if (res) {
                        window.location.href = res.data.url
                        /* document.body.appendChild(res.data.content); */
                        /* setModalVisible(true) */
                        /*      window.open(res.data.url) */
                        const buyedIdList = cartItems.map((v) => v.id);
                        const restList = cart.filter((val) => !buyedIdList.includes(val.id))
                        localStorage.setItem("rezervationInfo", JSON.stringify(restList))

                    }

                } catch (error) {
                    error?.response?.data.map((val, i) =>
                        openNotification({ message: val.message, type: "error" })
                    )
                }
            } else {
                openNotification({ message: t("sepetiçindenürünleriseçiniz"), type: "warning" })
            }
        }
        else if (cartItems.length < 1) {
            openNotification({ message: t("sepetiçindenürünleriseçiniz"), type: "warning" })
        } else {

            setPaymentModalVisible(true)
        }
    }

    const paymentNonMember = async () => {

        try {
            let res = await paymentNonMemberRequest(cartData)
            if (res) {
                window.location.href = res.data.url
                const buyedIdList = cartItems.map((v) => v.id);
                const restList = cart.filter((val) => !buyedIdList.includes(val.id))
                localStorage.setItem("rezervationInfo", JSON.stringify(restList))

            }

        } catch (error) {
            error?.response?.data.map((val, i) =>
                openNotification({ message: val.message, type: "error" })
            )
        }

    }


    const rezervation = async () => {

        setIsDisabled2(true)
        setTimeout(() => {
            setIsDisabled2(false)
        }, 1500);

        if (role) {
            if (cartItems.length > 0) {
                try {
                    let res = await rezervationRequest({
                        cartItems: cartItems,
                        currency: currency.toUpperCase(),
                        nonMember: nonMember
                    })
                    if (res) {
                        openNotification({ message: t("rezervasyonyapılmıştır"), type: "success" })
                        const buyedIdList = cartItems.map((v) => v.id);
                        const restList = cart.filter((val) => !buyedIdList.includes(val.id))
                        localStorage.setItem("rezervationInfo", JSON.stringify(restList))
                        setTimeout(() => {
                            navigate("../my-account")
                        }, 500);

                    }
                } catch (error) {
                    error?.response?.data.map((val, i) =>
                        openNotification({ message: val.message, type: "error" })
                    )
                }
            }
            else {
                openNotification({ message: t("sepetiçindenürünleriseçiniz"), type: "warning" })
            }
        } else if (cartItems.length < 1) {
            openNotification({ message: t("sepetiçindenürünleriseçiniz"), type: "warning" })
        } else {

            setRezervationModalVisible(true)
        }
    }

    const rezervationNonMember = async () => {
        try {
            let res = await rezervationNonMemberRequest({
                cartItems: cartItems,
                currency: currency.toUpperCase(),
                nonMember: nonMemberRez
            })
            if (res) {
                openNotification({ message: t("rezervasyonyapılmıştır"), type: "success" })
                const buyedIdList = cartItems.map((v) => v.id);
                const restList = cart.filter((val) => !buyedIdList.includes(val.id))
                localStorage.setItem("rezervationInfo", JSON.stringify(restList))
                setTimeout(() => {
                    window.location.reload()
                }, 500);

            }
        } catch (error) {
            error?.response?.data.map((val, i) =>
                openNotification({ message: val.message, type: "error" })
            )
        }
    }

    const deleteCartItem = (i, e) => {

        showDeleteConfirm(i, e)

    }

    function showDeleteConfirm(i, e) {
        confirm({
            title: <div>{`${e} `} {t("aktivitesisepettenkaldırılacak")}</div>,
            icon: <ExclamationCircleOutlined />,
            content: t("eminmisiniz"),
            okText: t("evet"),
            okType: 'danger',
            cancelText: t("iptal"),
            onOk() {
                cart.splice(i, 1)
                localStorage.setItem("rezervationInfo", JSON.stringify(cart))
                window.location.reload()
            },
            onCancel() {
            },
        });
    }


    const sendCart = async () => {
        try {
            let res = await postCartRequest({ cartItems: cart })
            if (res) {
                setShownData(res.data)
                setIsDisabled3(false)
            }
        } catch (error) {

        }
    }

    const checkAuthentication = async () => {
        try {
            let res = await getMeRequest()
            if (res?.status === 200 && res?.data?.role === "ROLE_ADMIN") {
                setRole("admin")
            } else if (res?.status === 200 && res?.data?.role === "ROLE_USER") {
                setRole("user")
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        getMyAccount()
        sendCart()
        checkAuthentication()
        window.scrollTo(0, 0)
        setNonMember({ ...nonMember, firstName: firstData?.firstName, lastName: firstData?.lastName, email: firstData?.email })
        setNonMemberRez({ ...nonMemberRez, firstName: firstData?.firstName, lastName: firstData?.lastName, email: firstData?.email })
        /*  localStorage.clear(); */
    }, [])

    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <Row gutter={[20, 20]} >
                            <Col span={24}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto" }}>
                                    <div className='search-top'>
                                        <Text className='kesfet-baslik'> {t("sepetim")}</Text>
                                    </div>
                                    <Row gutter={[20, 0]} className='cart-root'>
                                        <Col xs={24} sm={24} md={13} lg={15} xl={16} className='cart-item-left'>
                                            {isDisabled3 === false &&
                                                shownData.cartItems.length < 1 ? <div className="cart-empty">
                                                <ShoppingCartOutlined style={{ color: "#2C3E50", fontSize: "48px", paddingRight: "30px" }} />
                                                <Text className="cart-empty-text">{t("sepetinizdeürünbulunmamaktadır")}</Text>
                                            </div> : <></>}
                                            {isDisabled3 === false ?
                                                shownData.cartItems.map((val, i) => (
                                                    <div key={i} className="shopping-cart-root">
                                                        <Checkbox /* checked={true} */ value={i + 1} onChange={(e) => onChange(i, e)}></Checkbox>
                                                        <div className="shopping-cart-right">
                                                            <div className="shopping-cart-top">
                                                                <Row gutter={[10, 0]}>
                                                                    <Col>
                                                                        <Text className="shopping-cart-text1">{val.sideData[0].title}</Text>
                                                                    </Col>
                                                                    <Col>
                                                                        <Text className="shopping-cart-text2">{val.sideData[0].city}</Text>
                                                                    </Col>
                                                                </Row>
                                                                <Tooltip title="Sepetten Kaldır" >
                                                                    <Button onClick={() => deleteCartItem(i, val.sideData[0].title)} size='small' style={{ backgroundColor: "rgba(192, 57, 43, 0.1)", color: "rgba(126, 3, 3)", border: "none", boxShadow: "none" }} type="primary" shape="circle" icon={<CloseOutlined style={{ fontSize: "12px" }} />} />
                                                                </Tooltip>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                <Row className="shopping-cart-bottom" >
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={10} className='shopping-cart-bottom-outside' >
                                                                        <div className='shopping-cart-bottom-inside'>
                                                                            <Text className="shopping-cart-text3">{t("ziyaretçiler")} :</Text>
                                                                            <div style={{ display: "flex", flexDirection: "row", }}>
                                                                                {val?.travelerList?.adultData?.length > 0 ?
                                                                                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                                                                        <Text className='cart-text1'>{val?.travelerList?.adultData?.length}  </Text>
                                                                                        <Tooltip title={t("yetişkin2")}>
                                                                                            <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/ADULTS.svg" />} />
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    : <></>}
                                                                                {val?.travelerList?.childData?.length > 0 ?
                                                                                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                                                                        <Text className='cart-text1'>{val?.travelerList?.childData?.length}  </Text>
                                                                                        <Tooltip title={t("çocuk2")}>
                                                                                            <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/CHILDS.svg" />} />
                                                                                        </Tooltip>
                                                                                    </div> : <></>}
                                                                                {val?.travelerList?.infantData?.length > 0 ?
                                                                                    <div style={{ display: "flex", alignItems: "center", paddingLeft: "7px" }}>
                                                                                        <Text className='cart-text1'>{val?.travelerList?.infantData?.length}  </Text>
                                                                                        <Tooltip title={t("bebek2")}>
                                                                                            <Button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", border: "none", boxShadow: "none" }} icon={<img className="cart-icon" src="/assets/img/BABY.svg" />} />
                                                                                        </Tooltip>
                                                                                    </div> : <></>}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={7} className='shopping-cart-bottom-outside'>
                                                                        <div className='shopping-cart-bottom-inside'>
                                                                            <Text className="shopping-cart-text3">{t("tarih")} :</Text>
                                                                            <Text style={{ paddingLeft: "10px" }}> {val.activityDate}</Text>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={24} sm={24} md={24} lg={24} xl={7} className='shopping-cart-bottom-outside'>
                                                                        <div className='shopping-cart-bottom-inside'>
                                                                            <Text className="shopping-cart-text3">{t("fiyat")} :</Text>
                                                                            <Text style={{ paddingLeft: "10px" }}>{(val.sideData[0].totalPrice).toFixed(2)} {sign}</Text>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    /* <Row key={i} className='cart-items'>
                                                        <Col xs={12} sm={12} md={12} lg={5} xl={5} className='cart-ortala'>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Text className='tab1-baslik'>
                                                                    {val.sideData[0].title}
                                                                </Text>
                                                                <Text className='tab1-baslik'>
                                                                    {val.sideData[0].city}
                                                                </Text>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={5} xl={5} className='cart-ortala'>
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                {val?.travelerList?.adultData?.length > 0 ? <Text className='cart-text1'>{val?.travelerList?.adultData?.length}  <img className="cart-icon" src="/assets/img/ADULTS.svg" /></Text> : <></>}
                                                                {val?.travelerList?.childData?.length > 0 ? <Text className='cart-text1'>{val?.travelerList?.childData?.length}  <img className="cart-icon" src="/assets/img/CHILDS.svg" /></Text> : <></>}
                                                                {val?.travelerList?.infantData?.length > 0 ? <Text className='cart-text1'>{val?.travelerList?.infantData?.length}  <img className="cart-icon" src="/assets/img/BABY.svg" /></Text> : <></>}
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={5} xl={5} className='cart-ortala'>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Text className='tab1-text'>Tarih</Text>
                                                                <Text className='tab1-baslik'>
                                                                    {val.activityDate}
                                                                </Text>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={5} xl={5} className='cart-ortala'>
                                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Text className='tab1-text'>Fiyat</Text>
                                                                <Text className='tab1-baslik'>
                                                                    {val.sideData[0].totalPrice} $
                                                                </Text>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={2} xl={2} className='cart-ortala'>
                                                            <Checkbox onChange={(e) => onChange(i, e)}></Checkbox>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={12} lg={2} xl={2} className='cart-ortala'>
                                                            <Tooltip title="Sepetten Kaldır" >
                                                                <Button onClick={() => deleteCartItem(i, val.sideData[0].title)} style={{ backgroundColor: "lightgrey", color: "rgba(126, 3, 3)", border: "none", }} type="primary" shape="circle" icon={<DeleteOutlined style={{ fontSize: "15px" }} />} />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row> */
                                                ))
                                                : <></>}


                                        </Col>
                                        <Col xs={24} sm={24} md={11} lg={9} xl={8} style={{ display: "flex", justifyContent: "center" }} >
                                            <div className='cart-item-right'>
                                                <div className='cart-items-column'>
                                                    <div className='cart-items2'>
                                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                                            <Text className='tab1-baslik-s'>{t("toplamtutar")}:</Text>
                                                            <Text className='cart-text2'>{(totalPricePayment).toFixed(2)} {sign}</Text>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <div>
                                                                <InfoCircleOutlined className='cart-text3' />
                                                                <Text className='cart-text3' >{t("şimdiödemekiçin")}</Text>
                                                            </div>
                                                            <CustomButton loading={isDisabled} disabled={isDisabled} onClick={payment} text={t("ödemeadımınageç")} colorType={"login-button-blue"} />
                                                            <div>
                                                                <InfoCircleOutlined className='cart-text3' />
                                                                <Text className='cart-text3' >{t("aktivitealanındaeldenödemekiçin")}</Text>
                                                            </div>
                                                            <CustomButton loading={isDisabled2} disabled={isDisabled2} onClick={rezervation} text={t("rezervasyonutamamla")} colorType={"login-button-white"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Modal
                        title={t("ödeme")}
                        visible={paymentModalVisible}
                        onCancel={() => setPaymentModalVisible(false)}
                        footer={null}
                        className='payment-modal'
                        bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                    >
                        <div className="card-container">
                            <Tabs type="card">
                                <TabPane tab={t("girişyapveyaüyeol")} key="1">
                                    <div className="login-buttons-payment">
                                        <CustomButton onClick={() => navigate('../login')} text={t("girişyap")} colorType={"login-button-blue"} />
                                        <div className="login-default-text" >{t("veya")}</div>
                                        <CustomButton onClick={() => navigate('../register')} text={t("üyeol")} colorType={"login-button-transparent-blue"} />
                                    </div>
                                </TabPane>
                                <TabPane tab={t("girişyapmadandevamet")} key="3">
                                    <Row gutter={[20, 20]} className="rez-detail-middle">
                                        <Col span={24} className="rez-detail-middle-item1">
                                            <Input
                                                value={nonMember?.firstName}
                                                onChange={(e) => {
                                                    nonMember.firstName = e.target.value
                                                    setNonMember({ ...nonMember })
                                                }} className="rez-detail-middle-text2" placeholder={t("adınız")} />
                                            <Tooltip title={t("zorunlu")} >
                                                <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                            </Tooltip>
                                        </Col>
                                        <Col span={24} className="rez-detail-middle-item1">
                                            <Input
                                                value={nonMember?.lastName}
                                                onChange={(e) => {
                                                    nonMember.lastName = e.target.value
                                                    setNonMember({ ...nonMember })
                                                }} className="rez-detail-middle-text2" placeholder={t("soyadınız")} />
                                            <Tooltip title={t("zorunlu")} >
                                                <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                            </Tooltip>Ï
                                        </Col>
                                        <Col span={24} className="rez-detail-middle-item1">
                                            <Input
                                                value={nonMember?.email}
                                                onChange={(e) => {
                                                    nonMember.email = e.target.value
                                                    setNonMember({ ...nonMember })
                                                }} className="rez-detail-middle-text2" placeholder={t("email")} />
                                            <Tooltip title={t("zorunlu")} >
                                                <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                            </Tooltip>
                                        </Col>
                                        <Col span={24} className="rez-detail-middle-item1">
                                            <CustomButton onClick={paymentNonMember} text={t("öde")} colorType={"login-button-blue"} />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Modal>
                    <Modal
                        title={t("rezervasyon")}
                        visible={rezervationModalVisible}
                        onCancel={() => setRezervationModalVisible(false)}
                        footer={null}
                        className='payment-modal'
                        bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                    >
                        <div className="card-container">
                            <Tabs type="card">
                                <TabPane tab={t("girişyapveyaüyeol")} key="1">
                                    <div className="login-buttons-payment">
                                        <CustomButton onClick={() => navigate('../login')} text={t("girişyap")} colorType={"login-button-blue"} />
                                        <div className="login-default-text" >{t("veya")}</div>
                                        <CustomButton onClick={() => navigate('../register')} text={t("üyeol")} colorType={"login-button-transparent-blue"} />
                                    </div>
                                </TabPane>
                                <TabPane tab={t("girişyapmadandevamet")} key="3">
                                    <Row gutter={[20, 20]} className="rez-detail-middle">
                                        <Col span={24} className="rez-detail-middle-item1">
                                            <Input
                                                value={nonMemberRez?.firstName}
                                                onChange={(e) => {
                                                    nonMemberRez.firstName = e.target.value
                                                    setNonMemberRez({ ...nonMemberRez })
                                                }} className="rez-detail-middle-text2" placeholder={t("adınız")} />
                                            <Tooltip title={t("zorunlu")} >
                                                <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                            </Tooltip>
                                        </Col>
                                        <Col span={24} className="rez-detail-middle-item1">
                                            <Input
                                                value={nonMemberRez?.lastName}
                                                onChange={(e) => {
                                                    nonMemberRez.lastName = e.target.value
                                                    setNonMemberRez({ ...nonMemberRez })
                                                }} className="rez-detail-middle-text2" placeholder={t("soyadınız")} />
                                            <Tooltip title={t("zorunlu")} >
                                                <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                            </Tooltip>
                                        </Col>
                                        <Col span={24} className="rez-detail-middle-item1">
                                            <Input
                                                value={nonMemberRez?.email}
                                                onChange={(e) => {
                                                    nonMemberRez.email = e.target.value
                                                    setNonMemberRez({ ...nonMemberRez })
                                                }} className="rez-detail-middle-text2" placeholder={t("email")} />
                                            <Tooltip title={t("zorunlu")} >
                                                <img style={{ width: "5px", paddingBottom: "27px" }} src='/assets/img/required.svg' />
                                            </Tooltip>
                                        </Col>
                                        <Col span={24} className="rez-detail-middle-item1">
                                            <CustomButton onClick={rezervationNonMember} text={t("rezervasyonyap")} colorType={"login-button-blue"} />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Modal>
                    <Info />
                </div >
            </div >
        </StickyContainer >
    )
}

export default ShoppingCart


