import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons/lib/icons";
import { Alert, Button, Col, Input, notification, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/Buttons";
import { Link, useNavigate } from "react-router-dom";
import Info from "../../components/Info";
import Rezervation from "../../components/Rezervation";
import Card from "../../components/Card";
import StickyTop from "../../components/StickyTop";
import { StickyContainer } from "react-sticky";
import NavBar from "../../components/NavBar";
import { useTranslation } from 'react-i18next';
import { getActivitybyId, getActivityPhotobyId } from "../../api/controllers/activity-controller";
import { Carousel } from 'react-carousel-minimal';

const Detail = () => {
    const { t, i18n } = useTranslation("common");
    document.title = 'tripgoX | ' + t("detail")
    var url = window.location;
    let id = new URLSearchParams(url.search).get('id');
    let lastVisitedData = JSON.parse(localStorage.getItem("lastVisited") || "[]");


    let navigate = useNavigate();

    const [showNav, setShowNav] = useState();
    const [isVisible, setIsVisible] = useState(false)
    const [isVisible2, setIsVisible2] = useState(false)
    const [activityData, setActivityData] = useState({})
    const [photoData, setPhotoData] = useState()
    const [denem, setDenem] = useState(false)

    const openNotification = ({ message, type }) => {
        if (type === "info") {
            notification.info({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "warning") {
            notification.warning({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "error") {
            notification.error({
                message: message,
                placement: 'bottomLeft',
            });
        } else if (type === "success") {
            notification.success({
                message: message,
                placement: 'bottomLeft',
            });
        }
    };

    const fetchActivity = async () => {
        try {
            let res = await getActivitybyId(id)
            if (res) {
                setActivityData(res.data)
                const av = lastVisitedData.find(el => el.id == parseInt(id));
                if ((av !== undefined) === false) {
                    if (5 > lastVisitedData.length) {
                    }
                    else {
                        lastVisitedData.pop()
                    }
                    lastVisitedData.unshift(res.data)
                    localStorage.setItem('lastVisited', JSON.stringify(lastVisitedData))
                } else {
                    const av = lastVisitedData.find(el => el.id == parseInt(id))
                    const ab = lastVisitedData.filter((val => val.id !== av.id))
                    localStorage.setItem('lastVisited', JSON.stringify(ab))
                    setDenem(!denem)
                }
            }
        } catch (error) {
            error?.response?.data.map((val, i) =>
                openNotification({ message: val.message, type: "error" })
            )
        }
    }

    const fetchActivityPhoto = async () => {
        try {
            let res = await getActivityPhotobyId(id)
            if (res) {
                setPhotoData(
                    res.data.map((val, i) => (
                        {
                            image: val.url
                        }
                    ))
                )
                if (res.data.length === 0) {
                    setIsVisible2(true)
                }
                else {
                    setIsVisible(true)
                }
            }
        } catch (error) {
            error?.response?.data.map((val, i) =>
                openNotification({ message: val.message, type: "error" })
            )
        }
    }


    useEffect(() => {
        fetchActivity()
        fetchActivityPhoto()
        window.scrollTo(0, 0)

    }, [denem])


    return (
        <StickyContainer>
            <StickyTop contentLeft={'sticky-top-text-white'} contentRight={"sticky-top-text-white"} setShowNav={setShowNav} />
            <NavBar showNav={showNav} onHideNav={() => setShowNav(false)} setShowNav={setShowNav} />

            <div>
                <div className="search-root" style={{ backgroundImage: "url(/assets/img/SearchBackground.png)" }}>
                    <div className="search-root-1"></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    <div className='detail-root-2'>
                        <Row gutter={[20, 20]} >
                            <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={18}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "auto" }}>
                                    <div className='search-top'>
                                        <Text className='kesfet-baslik'>{activityData.city} - {activityData.title}</Text>
                                    </div>
                                    <div className='search-bottom'>
                                        <div className='arama-bottom'>
                                            {isVisible2 === true ? <div>
                                                <Alert message={t("fotoğrafyüklenirkenbirsıkıntıoluştu")} type="error" showIcon />
                                            </div> : <></>}
                                            {isVisible === false ?
                                                <></>
                                                : <Carousel
                                                    data={photoData}
                                                    time={5000}
                                                    width="100%"
                                                    radius="10px"
                                                    slideNumber={true}
                                                    slideNumberStyle={{
                                                        fontSize: '20px',
                                                        fontWeight: 'bold'
                                                    }}
                                                    captionPosition="bottom"
                                                    automatic={true}
                                                    dots={true}
                                                    pauseIconColor="white"
                                                    pauseIconSize="40px"
                                                    slideBackgroundColor="darkgrey"
                                                    slideImageFit="cover"
                                                    thumbnails={true}
                                                    thumbnailWidth="100px"
                                                    style={{
                                                        textAlign: "center",
                                                        maxWidth: "100%",
                                                        margin: "0px auto",
                                                        padding: "10px",
                                                        borderRadius: "10px",

                                                    }}
                                                />}
                                            <Row gutter={[10, 10]} className='detail-bottom-top'>
                                                <Col xs={1} sm={2} md={1} lg={3} xl={3} xxl={3} className='arama-bottom-divider1'></Col>
                                                <Col xs={19} sm={12} md={19} lg={14} xl={10} xxl={7}>
                                                    <Text className='detail-bottom-text1'>
                                                        {t("detaylar")}
                                                    </Text>
                                                </Col>
                                                <Col xs={3} sm={10} md={4} lg={7} xl={11} xxl={14} className='arama-bottom-divider2'></Col>
                                            </Row>

                                        </div>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: activityData.overview }} />
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={6}  >
                                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                    <Rezervation priceData={activityData} whiteText={"rezervasyonyaptır"} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Info />
                </div>
            </div>
        </StickyContainer>
    )
}

export default Detail


/*  */